import React from "react";
import contentLoaderLine from "../../../../assets/img/contentLoaderLine.svg";
import classes from "./ContentLoader.module.css";

const ContentLoader = ({ height, top = 0, bg, zIndex = false }) => {
  return (
    <div
      className={classes.LoaderWrapper}
      style={{
        zIndex: zIndex ? 2 : 1,
        background: bg ? "rgba(128,128,128,0.6)" : "rgba(251,251,251)",
        top: top,
        height: height ? "100vh" : "unset",
      }}
    >
      <div className={classes.AppSpinner}>
        <div className={classes.Spinner}>
          <img src={contentLoaderLine} alt="loader line" />
        </div>
      </div>
    </div>
  );
};

export default ContentLoader;
