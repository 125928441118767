import { v4 as uuidv4 } from "uuid";
import { globalOp } from "../store/global";

export const createNotification = ({
  type,
  children,
  className = false,
  link = false,
  action = false,
}) => {
  return async (dispatch) => {
    try {
      const id = uuidv4();

      dispatch(
        globalOp.handleAddNotification({
          id: id,
          children: children,
          type: type,
          className: className,
          link: link,
          action: action,
        })
      );
    } catch (e) {
      console.log("Can not create notification");
    }
  };
};
