import React from "react";
import styles from "./EmptyLanding.module.css";
import EmptyIcon from "../../../assets/icon/empty_lending_icon.png";
import CreateLandingButton from "../createLandingButton/CreateLandingButton";
import { ROLE, USERS_ROLE } from "../../../constants/tab";
import { useSelector } from "react-redux";
import { currentUserSel } from "../../../store/currentUser";

const EmptyLanding = () => {
  const currentUser = useSelector(currentUserSel.currentUser);

  return (
    <div className={styles.container}>
      <span className={styles.text}>Нет ни одного лендинга</span>
      <div className={styles.empty_icon}>
        <img src={EmptyIcon} alt="" />
      </div>
      {USERS_ROLE[currentUser.role] === ROLE.WEB_MASTER && (
        <CreateLandingButton />
      )}
    </div>
  );
};

export default EmptyLanding;
