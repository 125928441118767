import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { ACTIVE_TAB } from "../../constants/tab";
import { currentUserSel } from "../../store/currentUser";
import { usersSel } from "../../store/users";
import { delayByLoader } from "../../utils/utils";
import EmptyText from "../ui/emptyText/EmptyText";
import ContentLoader from "../ui/loaders/contentLoader/ContentLoader";
import styles from "./UsersContent.module.css";
import CardItem from "./userCard/cardItem/CardItem";

const UsersContent = () => {
  const [loader, setLoader] = useState(false);

  const usersListData = useSelector(usersSel.usersListData);
  const filteredUsersList = useSelector(usersSel.filteredUsersList);
  const selectedTab = useSelector(currentUserSel.selectedTab);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedTab !== ACTIVE_TAB.ALL) {
      setData([]);
    } else {
      setData(usersListData);
    }
  }, [selectedTab, usersListData]);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, [selectedTab]);

  return (
    <div className={styles.container}>
      <Container
        style={{
          minWidth: "100%",
          padding: "0px",
          position: "relative",
          minHeight: "24vh",
        }}
      >
        {loader && <ContentLoader />}
        <Row>
          {!loader &&
          selectedTab !== ACTIVE_TAB.ALL &&
          filteredUsersList.length > 0 ? (
            filteredUsersList.map((item) => (
              <Col
                key={item._id}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                className={styles.container}
              >
                <CardItem key={item.id} data={item} />
              </Col>
            ))
          ) : !loader && selectedTab === ACTIVE_TAB.ALL && data.length > 0 ? (
            data.map((item) => (
              <Col
                key={item._id}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                className={styles.container}
              >
                <CardItem key={item.id} data={item} />
              </Col>
            ))
          ) : (
            <EmptyText />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default UsersContent;
