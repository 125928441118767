import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROLE, USERS_ROLE } from "../../../../constants/tab";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { classes, getRoleText } from "../../../../utils/utils";
import styles from "./CardItem.module.css";

const CardItem = ({ data, activeCard, cardClickHeandler }) => {
  const [width] = useWindowSize();

  const cardRef = useRef();

  const [wordWidth, setWordWidth] = useState(
    cardRef.current?.getBoundingClientRect().width - 90
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (cardRef.current) {
      setWordWidth(cardRef.current?.getBoundingClientRect().width - 90);
    }
  }, [width]);

  return (
    <div
      id={`card_item_${data._id}`}
      className={classes(
        styles.content,
        activeCard === data._id && styles.active_content
      )}
      onClick={() => {
        if (!!cardClickHeandler) {
          cardClickHeandler(true, data._id);
        }

        navigate(`/user/${data._id}`);
      }}
      ref={cardRef}
    >
      <div className={styles.avatar}>
        <img
          width="38px"
          height="38px"
          src="https://cdn-icons-png.flaticon.com/512/147/147142.png"
          alt=""
        />
      </div>
      <div className={styles.text}>
        <span
          className={styles.name_text}
          style={{
            width: `calc(${wordWidth}px)`,
          }}
        >
          {`${data.name} ${data.surname}`}
        </span>
        <span
          className={
            USERS_ROLE[data.role] === ROLE.ADMIN
              ? styles.admin_text
              : styles.moderator_text
          }
        >
          {getRoleText(data.role)}
        </span>
      </div>
    </div>
  );
};

export default memo(CardItem);
