/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ACTIVE_TAB } from "../../constants/tab";
import { currentUserOp } from "../../store/currentUser";
import { globalOp } from "../../store/global";
import { usersOp } from "../../store/users";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const PrivateRoute = ({ auth, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [flag, setFlag] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    if (location.pathname.indexOf("/user/") !== -1 && flag) {
      dispatch(globalOp.handleSetContentLoader(true));

      setTimeout(() => {
        dispatch(globalOp.handleSetContentLoader(false));
      }, 600);

      setFlag(false);
    } else if (location.pathname.indexOf("/user/") === -1) {
      dispatch(globalOp.handleSetContentLoader(true));
      setTimeout(() => {
        dispatch(globalOp.handleSetContentLoader(false));
      }, 600);
      setFlag(true);
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (!(location.pathname.indexOf("/user/") !== -1)) {
      dispatch(currentUserOp.handleUpdateSelectedTab(ACTIVE_TAB.ALL));
      dispatch(usersOp.handleSetFilteredUsersList([]));
    }
  }, [location.pathname, dispatch]);

  return localStorage.getItem("token") ? (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: rest.location },
      }}
    />
  );
};

export default PrivateRoute;
