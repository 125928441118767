import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/fonts.css";
import "./assets/css/global.css";
import Layout from "./components/layout/Layout";
import Notifications from "./components/notifications/Notifications";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import { ROLE, USERS_ROLE } from "./constants/tab";
import Activation from "./pages/activationPage/Activation";
import CreateLandingPage from "./pages/createLandingPage/CreateLandingPage";
import EditLandingPage from "./pages/editLandingPage/EditLandingPage";
import HelpPage from "./pages/helpPage/HelpPage";
import Landing from "./pages/landingPage/LandingPage";
import Login from "./pages/loginPage/Login";
import ModeratorLandingPage from "./pages/moderatorLandingPage/ModeratorLandingPage";
import NotFound from "./pages/notFoundPage/NotFound";
import RecoveringPasswordPage from "./pages/recoveringPasswordPage/RecoveringPasswordPage";
import Registration from "./pages/registrationPage/Registration";
import UpdatePasswordPage from "./pages/updatePasswordPage/UpdatePasswordPage";
import UserDetailsPage from "./pages/userDetailsPage/UserDetailsPage";
import UsersPage from "./pages/usersPage/UsersPage";
import ViewLandingPage from "./pages/viewLandingPage/ViewLandingPage";
import { currentUserSel } from "./store/currentUser";

function App() {
  const isAuth = useSelector(currentUserSel.isAuth);
  const currentUser = useSelector(currentUserSel.currentUser);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* AuthPage */}
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/recover" element={<RecoveringPasswordPage />} />
            <Route
              path="/updatePassword/:id/:recoverId"
              element={<UpdatePasswordPage />}
            />
            <Route
              path="/user/profile/:activationLink"
              element={<Activation path="/user/profile/:activationLink" />}
            />
            <Route
              path="/view/:authorId/:landingId"
              element={<ViewLandingPage />}
            />
            {/* FrontPage */}
            <Route element={<PrivateRoute auth={isAuth} path="/" />}>
              <Route
                path="/landings"
                element={
                  USERS_ROLE[currentUser?.role] === ROLE.WEB_MASTER ? (
                    <Landing />
                  ) : (
                    <ModeratorLandingPage />
                  )
                }
              />
              <Route
                element={
                  USERS_ROLE[currentUser?.role] === ROLE.WEB_MASTER ? (
                    <Landing />
                  ) : (
                    <ModeratorLandingPage />
                  )
                }
              />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/editLanding/:id" element={<EditLandingPage />} />
              <Route path="/createLanding" element={<CreateLandingPage />} />

              {USERS_ROLE[currentUser?.role] === ROLE.ADMIN && (
                <>
                  <Route path="/users/" element={<UsersPage />} />
                  <Route path="/user/:id" element={<UserDetailsPage />} />
                  <Route path="/user/create" element={<UserDetailsPage />} />
                </>
              )}

              {/* 404 */}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <Notifications />
      </BrowserRouter>
    </div>
  );
}

export default App;
