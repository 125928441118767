import { ACTIVE_TAB } from "../../constants/tab";

export const initialUserState = {
  currentUser: {},
  isAuth: false,
  errors: false,
  selectedTab: ACTIVE_TAB.ALL,
  selectedLandingTab: 0,
  offers: [],
};
