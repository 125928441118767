import React from "react";
import EmailIcon from "../../assets/icon/mail_icon.png";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <span className={styles.question}>Возникли вопросы?</span>
        <div className={styles.email}>
          <img src={EmailIcon} alt="" />
          <a href="mailto:ecomxp@x-partners.com" className={styles.question}>
            ecomxp@x-partners.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
