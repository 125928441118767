import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TABS_DATA } from "../../../constants/tab";
import { currentUserOp, currentUserSel } from "../../../store/currentUser";
import { usersOp, usersSel } from "../../../store/users";
import { classes } from "../../../utils/utils";
import styles from "./Tab.module.css";

const Tab = () => {
  const dispatch = useDispatch();

  const usersListData = useSelector(usersSel.usersListData);

  const selectedTab = useSelector(currentUserSel.selectedTab);

  const onTabClickHandler = (item) => {
    dispatch(usersOp.handleSetFilteredUsersList([]));
    dispatch(currentUserOp.handleUpdateSelectedTab(item.type));

    if (!!item.role) {
      const data = usersListData.filter((el) => {
        return el.role === item.role;
      });
      dispatch(usersOp.handleSetFilteredUsersList(data));
    } else {
      dispatch(usersOp.handleSetFilteredUsersList([]));
    }
  };

  return (
    <div className={classes(styles.container)}>
      {TABS_DATA.map((item) => {
        return (
          <span
            key={item.type}
            className={classes(
              styles.item,
              selectedTab === item.type && styles.active_item
            )}
            onClick={() => onTabClickHandler(item)}
          >
            {item.text}
          </span>
        );
      })}
    </div>
  );
};

export default Tab;
