import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CopyIcon from "../../../../assets/icon/copy_icon.png";
import EyeIcon from "../../../../assets/icon/landing_eye_icon.png";
import RemoveIcon from "../../../../assets/icon/landing_remove_icon.png";
import { LANDING_STATUS_TYPE } from "../../../../constants/tab";
import { classes, delayByLoader, extractURL } from "../../../../utils/utils";
import Button from "../../../ui/button/Button";
import StatusText from "../../statusText/StatusText";
import styles from "./LandingItem.module.css";

const LandingItem = (props) => {
  const navigate = useNavigate();

  const { _id, landingName, publishedLink, link, status, comment } = props.data;

  const pbLink = extractURL(publishedLink);

  const [isCopy, setIsCopy] = useState(false);

  const onShowView = () => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Col
        lg={2}
        md={2}
        style={{
          paddingLeft: 0,
        }}
      >
        <div className={styles.word_size_control}>
          <span className={styles.title}>{landingName}</span>
        </div>
      </Col>
      <Col
        lg={3}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className={classes(styles.link, isCopy && styles.copy_animation)}>
          <div className={styles.word_size_control}>
            {(status === 1 && pbLink) ||
              (status === 3 && pbLink && (
                <span className={styles.link_text}>{pbLink}</span>
              ))}
          </div>
        </div>
        <div
          className={styles.link_icon}
          onClick={() => {
            delayByLoader(true, setIsCopy, 1000);
            navigator.clipboard.writeText(extractURL(publishedLink));
          }}
        >
          <img src={CopyIcon} alt="" />
        </div>
      </Col>

      <Col lg={2} md={2}>
        <StatusText status={status} />
      </Col>
      <Col lg={5} md={5}>
        <Row>
          <Col
            lg={7}
            md={7}
            style={{
              overflowX: "hidden",
            }}
            id={`app-title-${props.index}`}
          >
            {comment && (
              <div className={styles.word_size_control}>
                <span className={styles.comment}>{comment}</span>
              </div>
            )}

            <ReactTooltip
              anchorId={`app-title-${props.index}`}
              place="top"
              content={comment}
              className={styles.landing_item_tooltip}
              style={{}}
            />
          </Col>
          <Col lg={5} md={5} className={styles.landing_item_buttons}>
            <img
              className={styles.cursor_pointer}
              onClick={onShowView}
              width={24}
              height={24}
              src={EyeIcon}
              alt=""
            />
            <Button
              onClick={() => {
                navigate(`/editLanding/${_id}`);
              }}
              className={styles.edit_button}
            >
              Редактировать
            </Button>
            <img
              style={{
                pointerEvents:
                  status === LANDING_STATUS_TYPE.ARCHIVED ? "none" : "unset",
                opacity: status === LANDING_STATUS_TYPE.ARCHIVED ? 0.4 : 1,
              }}
              className={styles.cursor_pointer}
              onClick={() => {
                props.onSetModalShow(_id, landingName);
              }}
              width={24}
              height={24}
              src={RemoveIcon}
              alt=""
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default LandingItem;
