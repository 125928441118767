/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { checkAuth } from "../../service/user_api";
import { globalOp, globalSel } from "../../store/global";
import Notifications from "../notifications/Notifications";
import AppLoader from "../ui/loaders/appLoader/AppLoader";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const authLoader = useSelector(globalSel.authLoader);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(checkAuth(navigate, redirectLandings));
    } else {
      dispatch(globalOp.handleSetAuthLoader(false));

      redirectLandings();
      // setTimeout(() => {
      //   navigate("/login");
      // }, 2000);
    }
  }, [dispatch, navigate]);

  const redirectLandings = () => {
    if (location.pathname.length === 1) {
      navigate("/landings");
    }
  };

  if (authLoader) {
    return (
      <>
        <AppLoader />
        <Notifications />
      </>
    );
  }

  return <Outlet />;
};

export default Layout;
