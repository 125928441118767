import React from "react";
import styles from "./ResponseInfo.module.css";

const ResponseInfo = ({ email }) => {
  return (
    <div className={styles.container}>
      <span>
        На ваш email {email} отправлено письмо с дальнейшими инструкциями.
      </span>
    </div>
  );
};

export default ResponseInfo;
