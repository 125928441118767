export const ACTIVE_LANDING_TAB = {
  ALL: "ALL",
  ADMINS: "ADMIN",
  MODERATORS: "MODERATOR",
};

export const ACTIVE_TAB = {
  ALL: "ALL",
  ADMINS: "ADMIN",
  MODERATORS: "MODERATOR",
};

export const TABS_DATA = [
  {
    type: ACTIVE_TAB.ALL,
    text: "ВСЕ",
    role: 0,
  },
  {
    type: ACTIVE_TAB.ADMINS,
    text: "АДМИНИСТРАТОРЫ",
    role: 1,
  },
  {
    type: ACTIVE_TAB.MODERATORS,
    text: "МОДЕРАТОРЫ",
    role: 2,
  },
];

export const ROLE = {
  ADMIN: "ADMIN",
  MODERATOR: "MODERATOR",
  WEB_MASTER: "WEB_MASTER",
};

export const USERS_ROLE = {
  1: ROLE.ADMIN,
  2: ROLE.MODERATOR,
  3: ROLE.WEB_MASTER,
};

export const ACTION_TYPE = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
};

export const PASSWORD_VALIDATION_TEXT =
  "Длина пароля должна быть не меньше 8 и не больше 40 символов";

export const RADIO_DATA = [
  {
    name: "Администратор",
    role: 1,
  },
  {
    name: "Модератор",
    role: 2,
  },
];

export const LANDING_STATUS = {
  1: "Опубликован",
  2: "Не опубликован",
  3: "На модерации",
  4: "В архиве",
  5: "Черновик",
};

export const LANDING_STATUS_TYPE = {
  PUBLISHED: 1,
  NOT_PUBLISHED: 2,
  MODERATION: 3,
  ARCHIVED: 4,
  DRAFT: 5,
};
