import { usersSlice } from "./usersSlice";

const {
  setUsersListData,
  setFilteredUsersList,
  setSelectedUser,
  setActiveTab,
} = usersSlice.actions;

const handleSetUsersListData = (data) => (dispatch) => {
  dispatch(setUsersListData(data));
};

const handleSetFilteredUsersList = (data) => (dispatch) => {
  setTimeout(() => {
    dispatch(setFilteredUsersList(data));
  }, 100);
};

const handleSetSelectedUser = (data) => (dispatch) => {
  setTimeout(() => {
    dispatch(setSelectedUser(data));
  }, 200);
};

const handleSetActiveTab = (data) => (dispatch) => {
  dispatch(setActiveTab(data));
};

const operations = {
  handleSetUsersListData,
  handleSetFilteredUsersList,
  handleSetSelectedUser,
  handleSetActiveTab,
};

export default operations;
