import React from "react";
import styles from "./RadioButton.module.css";

const RadioButton = ({ data, value, setRole }) => {
  const { name, role } = data;

  return (
    <label key={name} htmlFor={`option_${role}`} className={styles.container}>
      <input
        type="radio"
        id={`option_${role}`}
        name="radioGroup"
        checked={+value === role}
        value={role}
        onChange={(e) => setRole(e.target.value)}
        className={styles.input}
      />
      <span className={styles.input_text}>{name}</span>
    </label>
  );
};

export default RadioButton;
