import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import { LANDING_STATUS_TYPE } from "../../../constants/tab";
import { getAllLanding, removeLanding } from "../../../service/landing_api";
import { currentUserSel } from "../../../store/currentUser";
import { landingSel } from "../../../store/landing";
import { delayByLoader } from "../../../utils/utils";
import LandingRemoveModal from "../../modals/landingRemoveModal/LandingRemoveModal";
import ContentLoader from "../../ui/loaders/contentLoader/ContentLoader";
import ColumTitle from "../columTitle/ColumTitle";
import CreateLandingButton from "../createLandingButton/CreateLandingButton";
import styles from "./LandingContent.module.css";
import LandingItem from "./landingItem/LandingItem";

const columnTitle = [
  {
    title: "Название",
    col: 2,
  },
  {
    title: "Ссылка",
    col: 3,
  },
  {
    title: "Статус",
    col: 2,
  },
  {
    title: "Комментарии",
    col: 5,
  },
];

const LandingContent = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editLandingData, setEditLandingData] = useState({
    id: null,
    title: "",
  });

  const landingsData = useSelector(landingSel.landingsData);
  const currentUser = useSelector(currentUserSel.currentUser);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, []);

  const removeHandler = () => {
    const body = {
      status: LANDING_STATUS_TYPE.ARCHIVED,
      id: editLandingData.id,
    };

    dispatch(removeLanding(body, onSuccess, setModalShow));
  };

  const onSuccess = () => {
    delayByLoader(true, setLoader, 300);
    dispatch(getAllLanding(currentUser.role));
    setModalShow(false);
  };

  const onSetModalShow = (id, title) => {
    if (!!id) {
      setModalShow(true);
      setEditLandingData({ id, title });
    }
  };

  return (
    <Container className={styles.container}>
      {loader && <ContentLoader />}
      <Row className={styles.header_row}>
        {columnTitle.map((item, i) => {
          return (
            <ColumTitle
              key={item.title}
              col={item.col}
              text={item.title}
              index={i}
            />
          );
        })}
      </Row>

      <div
        style={{
          marginBottom: 24,
        }}
      >
        {landingsData.map((item, index) => {
          return (
            <Row key={item._id} className={styles.content_row}>
              <LandingItem
                data={item}
                onSetModalShow={onSetModalShow}
                index={index}
              />
            </Row>
          );
        })}
      </div>
      <LandingRemoveModal
        data={editLandingData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        removeHandler={removeHandler}
      />
      <CreateLandingButton />
    </Container>
  );
};

export default LandingContent;
