import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { LANDING_STATUS_TYPE } from "../../../../constants/tab";
import {
  getAllLanding,
  landingModeration,
} from "../../../../service/landing_api";
import { currentUserSel } from "../../../../store/currentUser";
import {
  classes,
  extractURL,
  landingFormatDate,
} from "../../../../utils/utils";
import Button from "../../../ui/button/Button";
import ContentLoader from "../../../ui/loaders/contentLoader/ContentLoader";
import StatusText from "../../statusText/StatusText";
import styles from "./ModeratorLandingMobileItem.module.css";

const ModeratorLandingMobileItem = (props) => {
  const dispatch = useDispatch();

  const { _id, landingName, link, createdAt, publishedLink, status, comment } =
    props.data;

  const [componentLoader, setComponentLoader] = useState(false);
  const [commentText, setCommentText] = useState(comment);
  const [buttonState, setButtonState] = useState(false);

  const currentUser = useSelector(currentUserSel.currentUser);

  const onShowView = () => {
    window.open(link, "_blank");
  };

  const onLandingModerationHandler = (status) => {
    setButtonState(true);

    const body = {
      id: _id,
      status: status,
      comment: status === 2 ? commentText : "",
    };

    setCommentText(status === 2 ? commentText : "");

    dispatch(
      landingModeration(body, onSuccess, setButtonState, setComponentLoader)
    );
  };

  const onSuccess = (status) => {
    setButtonState(false);
    dispatch(getAllLanding(currentUser.role));
  };

  return (
    <>
      {componentLoader && <ContentLoader zIndex />}

      <Row className={styles.first_row_container}>
        <Col xs={4}>
          <div
            className={styles.word_size_control}
            onClick={() => {
              window.open(extractURL(publishedLink), "_blank");
            }}
          >
            <span className={styles.title}>{landingName}</span>
          </div>
        </Col>
        <Col xs={4} className={styles.content_center}>
          <StatusText status={status} />
        </Col>
        <Col xs={4} className={styles.content_flex_end}>
          <div
            className={classes(
              styles.word_size_control,
              styles.content_flex_end
            )}
          >
            <span className={styles.comment}>
              {landingFormatDate(createdAt)}
            </span>
          </div>
        </Col>
      </Row>
      <Row
        className={styles.second_row_container}
        style={{
          opacity:
            status === LANDING_STATUS_TYPE.ARCHIVED ||
            status === LANDING_STATUS_TYPE.DRAFT
              ? 0.3
              : 1,
          pointerEvents:
            status === LANDING_STATUS_TYPE.ARCHIVED ||
            status === LANDING_STATUS_TYPE.DRAFT
              ? "none"
              : "unset",
        }}
      >
        <div className={styles.second_row_container_content}>
          <div>
            <input
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={4}>
          <Button onClick={onShowView} className={styles.view_button}>
            предпросмотр
          </Button>
        </Col>
        <Col
          xs={4}
          className={styles.content_center}
          style={{
            opacity:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT ||
              status === LANDING_STATUS_TYPE.PUBLISHED
                ? 0.3
                : 1,
            pointerEvents:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT ||
              status === LANDING_STATUS_TYPE.PUBLISHED
                ? "none"
                : "unset",
          }}
        >
          <Button
            disable={buttonState}
            onClick={() => {
              onLandingModerationHandler(LANDING_STATUS_TYPE.PUBLISHED);
            }}
            className={classes(styles.view_button, styles.lunch_button)}
          >
            Опубликовать
          </Button>
        </Col>
        <Col
          xs={4}
          className={styles.content_flex_end}
          style={{
            opacity:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT
                ? 0.3
                : 1,
            pointerEvents:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT
                ? "none"
                : "unset",
          }}
        >
          <Button
            disable={buttonState}
            onClick={() => {
              onLandingModerationHandler(LANDING_STATUS_TYPE.NOT_PUBLISHED);
            }}
            className={classes(styles.view_button, styles.reject_button)}
          >
            Отклонить
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModeratorLandingMobileItem;
