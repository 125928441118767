import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { useDispatch, useSelector } from "react-redux";
import RejectIcon from "../../../../assets/icon/landing_reject_icon.png";
import ShowIcon from "../../../../assets/icon/show_landing_icon.png";
import { LANDING_STATUS_TYPE } from "../../../../constants/tab";
import {
  getAllLanding,
  landingModeration,
} from "../../../../service/landing_api";
import { currentUserOp, currentUserSel } from "../../../../store/currentUser";
import {
  classes,
  extractURL,
  landingFormatDate,
} from "../../../../utils/utils";
import Button from "../../../ui/button/Button";
import Loader from "../../../ui/loaders/loader/Loader";
import StatusText from "../../statusText/StatusText";
import styles from "./ModeratorLandingItem.module.css";

const ModeratorLandingItem = (props) => {
  const dispatch = useDispatch();

  const { _id, landingName, link, createdAt, publishedLink, status, comment } =
    props.data;

  const [componentLoader, setComponentLoader] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [commentText, setCommentText] = useState(comment);

  const currentUser = useSelector(currentUserSel.currentUser);

  const onShowView = () => {
    window.open(link, "_blank");
  };

  const onLandingModerationHandler = (status) => {
    setButtonState(true);
    const body = {
      id: _id,
      status: status,
      comment: status === 2 ? commentText : "",
    };

    setCommentText(status === 2 ? commentText : "");

    dispatch(
      landingModeration(body, onSuccess, setButtonState, setComponentLoader)
    );
  };

  const onSuccess = () => {
    setButtonState(false);
    dispatch(getAllLanding(currentUser.role));
    dispatch(
      currentUserOp.handleSetSelectedLandingTab(currentUser.role === 1 ? 0 : 3)
    );
  };

  return (
    <>
      {componentLoader && <Loader zIndex />}
      <Col
        lg={2}
        md={2}
        style={{
          paddingLeft: 0,
        }}
      >
        <div
          className={styles.word_size_control}
          style={{
            cursor: publishedLink ? "pointer" : "unset",
          }}
          onClick={() => {
            publishedLink && window.open(extractURL(publishedLink), "_blank");
          }}
        >
          <span className={styles.title}>{landingName}</span>
        </div>
      </Col>

      <Col lg={2} md={2}>
        <StatusText status={status} />
      </Col>
      <Col lg={2} md={2}>
        <div className={styles.word_size_control}>
          <span className={styles.comment}>{landingFormatDate(createdAt)}</span>
        </div>
      </Col>
      <Col lg={6} md={6} className={styles.buttons_container}>
        <div className={styles.buttons_container_firs_content}>
          <Button
            onClick={onShowView}
            icon={ShowIcon}
            className={styles.view_button}
          ></Button>
          <div
            style={{
              opacity:
                status === LANDING_STATUS_TYPE.ARCHIVED ||
                status === LANDING_STATUS_TYPE.DRAFT ||
                status === LANDING_STATUS_TYPE.PUBLISHED
                  ? 0.3
                  : 1,
              pointerEvents:
                status === LANDING_STATUS_TYPE.ARCHIVED ||
                status === LANDING_STATUS_TYPE.DRAFT ||
                status === LANDING_STATUS_TYPE.PUBLISHED
                  ? "none"
                  : "unset",
            }}
          >
            <Button
              disable={buttonState}
              onClick={() => {
                onLandingModerationHandler(LANDING_STATUS_TYPE.PUBLISHED);
              }}
              className={classes(styles.view_button, styles.lunch_button)}
            >
              Опубликовать
            </Button>
          </div>
          <div
            className={styles.buttons_container_input_content}
            style={{
              opacity:
                status === LANDING_STATUS_TYPE.ARCHIVED ||
                status === LANDING_STATUS_TYPE.DRAFT
                  ? 0.3
                  : 1,
              pointerEvents:
                status === LANDING_STATUS_TYPE.ARCHIVED ||
                status === LANDING_STATUS_TYPE.DRAFT
                  ? "none"
                  : "unset",
            }}
          >
            <input
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              type="text"
            />
          </div>
        </div>

        <div
          style={{
            opacity:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT
                ? 0.3
                : 1,
            pointerEvents:
              status === LANDING_STATUS_TYPE.ARCHIVED ||
              status === LANDING_STATUS_TYPE.DRAFT
                ? "none"
                : "unset",
          }}
        >
          <Button
            disable={buttonState}
            onClick={() => {
              onLandingModerationHandler(LANDING_STATUS_TYPE.NOT_PUBLISHED);
            }}
            icon={RejectIcon}
            className={classes(styles.view_button, styles.reject_button)}
          ></Button>
        </div>
      </Col>
    </>
  );
};

export default ModeratorLandingItem;
