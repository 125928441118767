import React, { useEffect, useRef } from "react";
import styles from "./EmptyText.module.css";

const EmptyText = () => {
  const textRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.style.display = "flex";
      }
    }, 100);
  }, []);

  return (
    <span ref={textRef} className={styles.empty_text}>
      Пусто
    </span>
  );
};

export default EmptyText;
