/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditLandingContent from "../../components/landingPage/editLandingContent/EditLandingContent";
import EditLandingLifeCycle from "../../components/landingPage/moderatorLandingContent/editLandingLifeCycle/EditLandingLifeCycle";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import PageTitle from "../../components/ui/pageTitle/PageTitle";
import { getLandingById } from "../../service/landing_api";
import { globalSel } from "../../store/global";
import { landingSel } from "../../store/landing";
import styles from "./EditLandingPage.module.css";

const EditLandingPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const contentLOader = useSelector(globalSel.contentLOader);
  const landingSingleData = useSelector(landingSel.landingSingleData);

  useEffect(() => {
    dispatch(getLandingById(id));
  }, []);

  return (
    <>
      {contentLOader && <ContentLoader top={84} zIndex />}
      {Object.keys(landingSingleData).length > 0 && (
        <div className={styles.container}>
          <PageTitle title="Редактирование лендинга" />
          <EditLandingLifeCycle data={landingSingleData} />
          <EditLandingContent data={landingSingleData} id={id} />
        </div>
      )}
    </>
  );
};

export default EditLandingPage;
