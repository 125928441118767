import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import appLoaderLine from "../../../../assets/img/appLoaderLine.svg";
import appLoader from "../../../../assets/img/loader.svg";
import { globalSel } from "../../../../store/global";
import classes from "./AppLoader.module.css";

const AppLoader = () => {
  const isLoading = useSelector(globalSel.loader);

  useEffect(() => {
    document.body.classList.toggle("hidden", isLoading);
  }, [isLoading]);

  return (
    <div className={classes.LoaderWrapper}>
      <div className={classes.AppSpinner}>
        <img src={appLoader} alt="x-partners logo" />
        <div className={classes.Spinner}>
          <img src={appLoaderLine} alt="loader line" />
        </div>
      </div>
    </div>
  );
};

export default AppLoader;
