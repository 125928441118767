import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileIcon from "../../assets/icon/icon_attach.png";
import { sendEmail } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import {
  delayByLoader,
  dispatchErrorNotification,
  validateEmailText,
} from "../../utils/utils";
import Button from "../ui/button/Button";
import ContentLoader from "../ui/loaders/contentLoader/ContentLoader";
import styles from "./HelpContent.module.css";

const HelpContent = () => {
  const dispatch = useDispatch();

  const currenUser = useSelector(currentUserSel.currentUser);

  const [contentLoader, setContentLoader] = useState(true);
  const [text, setText] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    delayByLoader(true, setContentLoader, 500);
  }, []);

  const onSetFileHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmitHandler = (e) => {
    const trimmedMessage = text.trim();

    if (!validateEmailText(trimmedMessage)) {
      dispatchErrorNotification(
        dispatch,
        `Длина сообщения должна быть не меньше 2 символов`
      );
    } else {
      const body = {
        id: currenUser.affiseId,
        email: currenUser.email,
        text,
        file,
      };

      dispatch(sendEmail(body, setContentLoader, onSuccess));
    }
  };

  const onSuccess = () => {
    setText("");
    setFile("");
  };

  return (
    <div className={styles.container}>
      {contentLoader && <ContentLoader />}

      <textarea
        placeholder="Если вы столкнулись с проблемной, опишите подробно: как она проявлеяется, какие действия и в каком порядке вы совершали. По возможности приложите скриншот."
        className={styles.myTextarea}
        value={text}
        onChange={(e) => setText(e.target.value)}
      ></textarea>
      <div className={styles.buttons}>
        <div className={styles.file_button}>
          <div>
            <img src={FileIcon} alt="" />
          </div>
          <div>
            <label
              className={styles.file_input_label}
              htmlFor="file-input-avatar"
            >
              {file.name ? file.name : "Прикрепить файл"}
            </label>

            <input
              accept="image/*"
              id="file-input-avatar"
              type="file"
              name="file"
              onChange={onSetFileHandler}
              style={{
                position: "absolute",
                top: "10%",
                left: "-50%",
                zIndex: -10,
                opacity: 0,
                cursor: "pointer",
                border: "1px solid green",
              }}
            />
          </div>
        </div>
        <div>
          <Button onClick={onSubmitHandler} className={styles.send_button}>
            отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelpContent;
