import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { currentUserSel } from "../../../store/currentUser";
import { delayByLoader } from "../../../utils/utils";
import ContentLoader from "../../ui/loaders/contentLoader/ContentLoader";
import styles from "./ModeratorLandingMobileItem.module.css";
import ModeratorLandingMobileItem from "./moderatorLandingMobileItem/ModeratorLandingMobileItem";

const ModeratorLandingMobileContent = ({ data }) => {
  const [width] = useWindowSize();
  const [loader, setLoader] = useState(false);
  const selectedLandingTab = useSelector(currentUserSel.selectedLandingTab);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, []);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, [selectedLandingTab]);

  return (
    <div className={styles.container}>
      {loader && <ContentLoader top={width <= 690 ? 210 : 170} />}

      <div
        style={{
          marginBottom: 24,
        }}
      >
        {data.map((item, i) => {
          return (
            <div key={item._id} className={styles.item_wrapper}>
              <ModeratorLandingMobileItem data={item} index={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModeratorLandingMobileContent;
