/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddUserIcon from "../../../assets/icon/add_user_icon.png";
import RemoveUserIcon from "../../../assets/icon/remove_user_icon.png";
import TabAndCreate from "../../../components/tabAndCreate/TabAndCreate";
import {
  ACTION_TYPE,
  ACTIVE_TAB,
  PASSWORD_VALIDATION_TEXT,
  RADIO_DATA,
  USERS_ROLE,
} from "../../../constants/tab";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { createNotification } from "../../../service/app";
import {
  createPrivilegedUser,
  getPrivilegedUsers,
  removePrivilegedUser,
  updatePrivilegedUser,
} from "../../../service/user_api";
import { currentUserOp, currentUserSel } from "../../../store/currentUser";
import { globalOp, globalSel } from "../../../store/global";
import { usersOp, usersSel } from "../../../store/users";
import {
  classes,
  delayByLoader,
  dispatchErrorNotification,
  getTabNumber,
  validateEmail,
  validatePassword,
  validateText,
} from "../../../utils/utils";
import UserRemoveModal from "../../modals/userRemoveModal/UserRemoveModal";
import Button from "../../ui/button/Button";
import EmptyText from "../../ui/emptyText/EmptyText";
import Input from "../../ui/inputs/input/Input";
import ContentLoader from "../../ui/loaders/contentLoader/ContentLoader";
import RadiosButton from "../../ui/radioButton/RadioButton";
import GoToList from "../goToList/GoToList";
import CardItem from "../userCard/cardItem/CardItem";
import styles from "./SelectedContent.module.css";

const SelectedContent = () => {
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const leftContentRef = useRef(null);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const [contentLoader, setContentLoader] = useState(false);
  const [tabLoader, setTabLoader] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [data, setData] = useState([]);

  const usersListData = useSelector(usersSel.usersListData);
  const filteredUsersList = useSelector(usersSel.filteredUsersList);
  const selectedUserData = useSelector(usersSel.selectedUser);
  const currentUser = useSelector(currentUserSel.currentUser);
  const selectedTab = useSelector(currentUserSel.selectedTab);

  useEffect(() => {
    if (width >= 992) setIsOpen(true);
  }, [width]);

  const onScrollHandler = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (id) {
      const el = document.getElementById(`card_item_${id}`);

      if (leftContentRef.current) {
        if (!selectedUserData?.new) {
          leftContentRef.current.scrollTop =
            el?.getBoundingClientRect().top -
            el?.getBoundingClientRect().height * 2.52;
        } else {
          leftContentRef.current.scrollTop = 0;
        }
      }
    }
  }, [id, selectedUserData?.new]);

  useEffect(() => {
    delayByLoader(true, setTabLoader, 300);
  }, [selectedTab]);

  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (flag) {
      dispatch(getPrivilegedUsers(currentUser.role));
      setFlag(false);
    }

    return () => {
      setFlag(true);

      dispatch(usersOp.handleSetUsersListData([]));
      dispatch(usersOp.handleSetFilteredUsersList([]));
    };
  }, []);

  useEffect(() => {
    const role = getTabNumber(selectedTab);

    if (selectedTab !== ACTIVE_TAB.ALL) {
      const data = usersListData.filter((el) => {
        return el.role === role;
      });
      setData([]);
      dispatch(usersOp.handleSetFilteredUsersList(data));
    } else {
      setData(usersListData);
    }
  }, [dispatch, selectedTab, usersListData]);

  useEffect(() => {
    if (id) {
      setName(selectedUserData?.name);
      setSurname(selectedUserData?.surname);
      setEmail(selectedUserData?.email);
      setRole(selectedUserData?.role);
      setPassword("");
    } else {
      onScrollHandler();
      onClearFields();
    }
  }, [id, onScrollHandler, selectedUserData]);

  const onClearFields = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPassword("");
    setRole(1);
  };

  useEffect(() => {
    onScrollHandler();

    return () => {
      leftContentRef.current = null;
    };
  }, [onScrollHandler]);

  useEffect(() => {
    delayByLoader(true, setContentLoader, 500);

    if (usersListData?.length > 0) {
      const data = usersListData.find((item) => item._id === id);
      dispatch(usersOp.handleSetSelectedUser(data));
      setIsOpen(true);
      setTimeout(() => {
        setContentLoader(false);
      }, 500);
    }
  }, [dispatch, id, usersListData, selectedUserData]);

  const onCancelHandler = () => {
    navigate("/users");
  };

  const onAddUserHandler = () => {
    const trimmedName = name.trim();
    const trimmedSurname = surname.trim();
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    if (!validateText(trimmedName)) {
      dispatchErrorNotification(
        dispatch,
        `Длина имени должна быть не меньше 2 и не больше 20 символов`
      );
    } else if (!validateText(trimmedSurname)) {
      dispatchErrorNotification(
        dispatch,
        `Длина фамилии должна быть не меньше 2 и не больше 20 символов`
      );
    } else if (!validateEmail(trimmedEmail)) {
      dispatchErrorNotification(
        dispatch,
        `Некорректный email. Пожалуйста, проверьте корректность ввода`
      );
    } else if (!validatePassword(trimmedPassword)) {
      dispatchErrorNotification(
        dispatch,
        `Длина пароля должна быть не меньше 8 и не больше 40 символов`
      );
    } else {
      dispatch(
        createPrivilegedUser(
          name,
          surname,
          email,
          password,
          role,
          setContentLoader,
          onSuccess
        )
      );
    }
  };

  const onSuccess = (type, id, role) => {
    onClearFields();
    dispatch(getPrivilegedUsers(currentUser.role));

    if (type === ACTION_TYPE.ADD || type === ACTION_TYPE.REMOVE) {
      navigate("/users");

      setIsOpen(false);
      setModalShow(false);
    }

    if (type === ACTION_TYPE.UPDATE) {
      navigate("/users");
      if (selectedTab !== ACTIVE_TAB.ALL) {
        dispatch(currentUserOp.handleUpdateSelectedTab(USERS_ROLE[role]));
      }
    }
  };

  const removeHeandler = () => {
    setModalShow(true);
  };

  const onRemovePrivilegedUser = () => {
    dispatch(removePrivilegedUser(id, setContentLoader, onSuccess));
  };

  const onUpdatePrivilegedUser = () => {
    const pass = validatePassword(password);

    const trimmedName = name.trim();
    const trimmedSurname = surname.trim();
    const trimmedEmail = email.trim();

    if (!validateText(trimmedName)) {
      dispatchErrorNotification(
        dispatch,
        `Длина имени должна быть не меньше 2 и не больше 20 символов`
      );
    } else if (!validateText(trimmedSurname)) {
      dispatchErrorNotification(
        dispatch,
        `Длина фамилии должна быть не меньше 2 и не больше 20 символов`
      );
    } else if (!validateEmail(trimmedEmail)) {
      dispatchErrorNotification(
        dispatch,
        `Некорректный email. Пожалуйста, проверьте корректность ввода`
      );
    } else {
      if (password.length === 0 || (password.length > 0 && pass)) {
        dispatch(
          updatePrivilegedUser(
            id,
            name,
            surname,
            email,
            password,
            role,
            setContentLoader,
            onSuccess
          )
        );
        return;
      }

      if (!pass) {
        dispatch(
          createNotification({
            type: "error",
            children: PASSWORD_VALIDATION_TEXT,
          })
        );
      }
    }
  };

  const onCreateNewUserHandler = () => {
    delayByLoader(true, setContentLoader, 500);
    setIsOpen(true);
    navigate("/user/create");
  };

  const contentLOader = useSelector(globalSel.contentLOader);

  const cardClickHeandler = (value, cardId) => {
    width < 992 && delayByLoader(true, setContentLoader, 500);
    setIsOpen(value);
  };

  const onGoBackHandler = () => {
    if (width < 992) {
      dispatch(globalOp.handleSetContentLoader(true));
      setTimeout(() => {
        dispatch(globalOp.handleSetContentLoader(false));
      }, 500);
    }

    setIsOpen(false);
  };

  return contentLOader ? (
    <ContentLoader top={18} height zIndex />
  ) : (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            width: width < 992 ? "100%" : "328px",
          }}
        >
          <div
            className={styles.left_content}
            style={{
              width: width < 992 ? "100%" : "328px",
            }}
          >
            {width < 992 && (
              <div
                className={styles.create_button_wrapper}
                style={{ position: "relative !important" }}
              >
                <Button
                  className={classes(
                    styles.selected_create_user_button,
                    styles.selected_create_user_button_mob
                  )}
                  icon={AddUserIcon}
                  onClick={onCreateNewUserHandler}
                >
                  ДОБАВИТЬ ПОЛьЗОВАТЕЛЯ
                </Button>
              </div>
            )}

            <TabAndCreate selectedUserData={selectedUserData} />

            <div
              style={{
                position: "relative",
                minHeight: "64vh",
              }}
            >
              {tabLoader && <ContentLoader />}
              <div
                ref={leftContentRef}
                className={styles.left_content_users_list}
                style={{
                  overflowY: "auto",
                }}
              >
                {filteredUsersList.length > 0 ? (
                  filteredUsersList.map((item) => (
                    <CardItem
                      key={item._id}
                      data={item}
                      activeCard={id}
                      cardClickHeandler={cardClickHeandler}
                    />
                  ))
                ) : selectedTab === ACTIVE_TAB.ALL && data.length > 0 ? (
                  data.map((item) => (
                    <CardItem
                      key={item._id}
                      data={item}
                      activeCard={id}
                      cardClickHeandler={cardClickHeandler}
                    />
                  ))
                ) : (
                  <EmptyText />
                )}
              </div>
            </div>
          </div>
        </div>
        <Col
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className={styles.right_content}
          style={{
            display:
              width >= 992 ? "unset" : width < 992 && isOpen ? "unset" : "none",
            marginTop: width <= 992 ? "-15px" : "0",
            zIndex: 1,
          }}
        >
          {width >= 992 && (
            <div className={styles.create_button_wrapper}>
              <Button
                className={classes(styles.selected_create_user_button)}
                icon={AddUserIcon}
                onClick={onCreateNewUserHandler}
              >
                ДОБАВИТЬ ПОЛьЗОВАТЕЛЯ
              </Button>
            </div>
          )}
          <div
            style={{
              position: "relative",
              height: "64vh",
              width: "100%",
            }}
          >
            {contentLoader ? (
              <ContentLoader top={78} zIndex />
            ) : (
              <>
                {width < 992 && <GoToList setIsOpen={onGoBackHandler} />}
                <div className={styles.form}>
                  <p className={styles.name}>
                    {id
                      ? `${selectedUserData?.name} ${selectedUserData?.surname}`
                      : "Новый пользователь"}
                  </p>
                  <Input
                    className={styles.input}
                    typeInput="authorization"
                    type="text"
                    label="ИМЯ"
                    placeholder="Введите Имя"
                    value={name}
                    setValue={setName}
                  />
                  <Input
                    className={styles.input}
                    typeInput="authorization"
                    type="text"
                    label="ФАМИЛИЯ"
                    placeholder="Введите Фамилию  "
                    value={surname}
                    setValue={setSurname}
                  />
                  <Input
                    className={styles.input}
                    typeInput="authorization"
                    type="email"
                    label="E-MAIL:"
                    placeholder="Введите email"
                    value={email}
                    setValue={setEmail}
                  />
                  <Input
                    className={styles.input}
                    typeInput="authorization"
                    type="password"
                    label="Пароль:"
                    placeholder="Введите пароль"
                    value={password}
                    setValue={setPassword}
                  />
                </div>
                <div className={styles.users_type}>
                  <p className={styles.user_type_title}>Тип пользователя</p>
                  {RADIO_DATA.map((item, i) => {
                    return (
                      <RadiosButton
                        key={i}
                        data={item}
                        value={role}
                        setRole={setRole}
                      />
                    );
                  })}
                </div>
                <div className={styles.buttons}>
                  {!id ? (
                    <>
                      <Button
                        onClick={onCancelHandler}
                        className={styles.cancel_user_button}
                      >
                        ОТМЕНИТЬ
                      </Button>
                      <Button
                        onClick={onAddUserHandler}
                        className={styles.save_user_button}
                      >
                        сохранить
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className={styles.remove_user_button}
                        icon={RemoveUserIcon}
                        onClick={removeHeandler}
                      >
                        УДАЛИТЬ ПОЛьЗОВАТЕЛЯ
                      </Button>
                      <Button
                        className={styles.save_user_button}
                        onClick={onUpdatePrivilegedUser}
                      >
                        ОБНОВИТЬ
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Col>
      </div>
      <UserRemoveModal
        data={selectedUserData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        removePrivilegedUser={onRemovePrivilegedUser}
      />
    </div>
  );
};

export default memo(SelectedContent);
