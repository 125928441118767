import React from "react";
import { useNavigate } from "react-router-dom";
import AddLandingIcon from "../../../assets/icon/add_user_icon.png";
import Button from "../../ui/button/Button";
import styles from "./CreateLandingButton.module.css";

const CreateLandingButton = () => {
  const navigate = useNavigate();

  const addLandingHandler = () => {
    navigate("/createLanding");
  };

  return (
    <div className={styles.container}>
      <Button
        onClick={addLandingHandler}
        className={styles.add_landing_button}
        icon={AddLandingIcon}
      >
        создать страницу
      </Button>
    </div>
  );
};

export default CreateLandingButton;
