import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BurgerClose from "../../assets/icon/burger-close.svg";
import BurgerMenu from "../../assets/icon/burger-menu.svg";
import logoutIcon from "../../assets/icon/logout-icon.svg";
import Logo from "../../assets/img/logo.svg";
import { ROLE, USERS_ROLE } from "../../constants/tab";
import { useWindowSize } from "../../hooks/useWindowSize";
import { logout } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import CustomLink from "../ui/customLink/CustomLink";
import styles from "./Navbar.module.css";
import MobileNavbar from "./mobileNavbar/MobileNavbar";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [width] = useWindowSize();

  const [menuState, setMenuState] = useState(false);
  const currentUser = useSelector(currentUserSel.currentUser);

  useEffect(() => {
    setMenuState(false);
  }, [location.pathname]);

  const onLogoutHandler = () => {
    setMenuState(false);
    dispatch(logout(navigate));
  };

  const toggleMenuState = () => {
    setMenuState(!menuState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Link to="/landings">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className={styles.links}>
          <div className={styles.left_content}>
            <CustomLink text="ЛЕНДИНГИ" to="/landings" />
            {USERS_ROLE[currentUser.role] === ROLE.WEB_MASTER && (
              <CustomLink text="Помощь" to="/help" />
            )}
            {USERS_ROLE[currentUser.role] === ROLE.ADMIN && (
              <CustomLink text="ПОЛЬЗОВАТЕЛИ" to="/users" />
            )}
          </div>

          <div className={styles.right_content}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "rpxight",
                marginRight: "8px",
              }}
            >
              {width >= 1024 && currentUser.managerEmail && (
                <div>
                  <span className={styles.your_manager_text}>
                    Ваш менеджер:{" "}
                  </span>
                  <span className={styles.your_manager_email}>
                    {currentUser.managerEmail}
                  </span>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span className={styles.email}>{currentUser.email}</span>
                <img
                  onClick={onLogoutHandler}
                  className={styles.logout}
                  src={logoutIcon}
                  alt=""
                />
              </div>
            </div>

            <button
              onClick={() => {
                window.open("https://my.x-partners.com/", "_blank");
              }}
              className={styles.button}
              style={{
                marginTop: currentUser.managerEmail ? "10px" : "0px",
              }}
            >
              В Affise
            </button>
          </div>
          <div className={styles.burger_menu}>
            <img
              onClick={toggleMenuState}
              src={menuState ? BurgerClose : BurgerMenu}
              alt=""
            />
          </div>
        </div>
        {menuState && (
          <MobileNavbar
            onLogoutHandler={onLogoutHandler}
            setMenuState={setMenuState}
            email={currentUser.email}
            managerEmail={currentUser.managerEmail}
            role={currentUser.role}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
