/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import Button from "../../components/ui/button/Button";
import Input from "../../components/ui/inputs/input/Input";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { activationAccount } from "../../service/user_api";
import classes from "./Activation.module.css";

const Activation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { activationLink } = useParams();

  const [data, setData] = useState({
    password: "",
    passwordAgain: "",
    activationLink: activationLink,
  });
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      data.password === data.passwordAgain &&
      data.password.length >= 8 &&
      data.passwordAgain.length >= 8
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [data]);

  const onActivation = () => {
    dispatch(activationAccount(data, onSuccess));
  };

  const onSuccess = () => {
    navigate("/landings");
  };

  return (
    <div className={classes.Login}>
      <div className={classes.Logo}>
        <img src={Logo} alt="x-partners logo" />
      </div>
      <div className={classes.Content}>
        {loading ? <ContentLoader /> : <div></div>}
        <h1 className={classes.Title}>Activation account</h1>
        <div className={classes.InputBlock}>
          <Input
            className={classes.Input}
            typeInput="authorization"
            type="password"
            label="password"
            placeholder="password"
            required={true}
            value={data.password}
            setValue={(val) => {
              setData({ ...data, password: val });
            }}
          />
          <Input
            className={classes.Input}
            typeInput="authorization"
            type="password"
            label="password again"
            placeholder="Password again"
            required={true}
            value={data.passwordAgain}
            setValue={(val) => {
              setData({ ...data, passwordAgain: val });
            }}
          />

          <div className={classes.FootBlock}>
            {valid ? (
              <Button
                className={classes.registration_button}
                onClick={onActivation}
              >
                Save
              </Button>
            ) : (
              <p>Пароли должны совпадать и иметь больше 8 символов</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activation;
