import { createSlice } from "@reduxjs/toolkit";
import { initialUsersState } from "./initialState";

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    setUsersListData(state, action) {
      state.usersListData = action.payload;
    },
    setFilteredUsersList(state, action) {
      state.filteredUsersList = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
  },
});
