/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import SelectedContent from "../../components/usersContent/selectedContent/SelectedContent";
import { getPrivilegedUsers } from "../../service/user_api";
import { globalSel } from "../../store/global";
import { usersOp, usersSel } from "../../store/users";
import styles from "./UserDetailsPage.module.css";

const UserDetailsPage = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const userListData = useSelector(usersSel.usersListData);
  const loader = useSelector(globalSel.loader);

  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (flag) {
      dispatch(getPrivilegedUsers());
      setFlag(false);
    }

    return () => {
      setFlag(true);
    };
  }, [dispatch]);

  useEffect(() => {
    if (userListData) {
      const data = userListData.find((item) => item._id === id);

      dispatch(usersOp.handleSetSelectedUser(data));
    }
  }, [userListData]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Профиль пользователя</h2>
      {loader && <ContentLoader />}
      <SelectedContent />
    </div>
  );
};

export default UserDetailsPage;
