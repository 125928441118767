import { LANDING_STATUS_TYPE } from "../constants/tab";
import $api from "../core/api";
import { landingOp } from "../store/landing";
import { delayByLoader } from "../utils/utils";
import { createNotification } from "./app";

export const getAllLanding = (role) => {
  return async (dispatch) => {
    try {
      const response = await $api.get(`/api/landing?role=${role}`);
      dispatch(landingOp.handleSetLandingsData(response.data));
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const getLandingById = (id) => {
  return async (dispatch) => {
    try {
      const response = await $api.get(
        `/api/landing/singleLanding?landingId=${id}`
      );
      dispatch(landingOp.handleSetLandingSingleData(response.data));
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const createLanding = (body, setContentLoader, onSuccess, status) => {
  return async (dispatch) => {
    setContentLoader(true);
    try {
      await $api.post(
        status === LANDING_STATUS_TYPE.DRAFT
          ? "/api/landing/createDraftLanding"
          : "/api/landing/createLanding",
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(
        createNotification({
          type: "success",
          children:
            status === LANDING_STATUS_TYPE.DRAFT
              ? `Лендинг успешно сохранен`
              : status === LANDING_STATUS_TYPE.ARCHIVED
              ? `Лендинг успешно сохранен`
              : `Лендинг отправлен на модерацию`,
        })
      );

      onSuccess();
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    } finally {
      setTimeout(() => {
        setContentLoader(false);
      }, 200);
    }
  };
};

export const updateLanding = (body, setContentLoader, onSuccess, status) => {
  setContentLoader(true);
  return async (dispatch) => {
    try {
      const response = await $api.post(
        status === LANDING_STATUS_TYPE.DRAFT
          ? "/api/landing/updateDraftLanding"
          : "/api/landing/updateLanding",
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(
        createNotification({
          type: "success",
          children:
            status === LANDING_STATUS_TYPE.DRAFT
              ? `Лендинг успешно сохранен`
              : response.data.status === LANDING_STATUS_TYPE.ARCHIVED
              ? `Лендинг успешно сохранен`
              : `Лендинг успешно обновлён`,
        })
      );

      onSuccess();
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    } finally {
      setContentLoader(false);
    }
  };
};

export const landingModeration = (
  body,
  onSuccess,
  setButtonState,
  setComponentLoader
) => {
  return async (dispatch) => {
    try {
      setComponentLoader?.(true);

      const response = await $api.post(`/api/landing/landingModeration`, body);

      const status = response.data.status;

      dispatch(
        createNotification({
          type: "success",
          children:
            status === LANDING_STATUS_TYPE.PUBLISHED
              ? `Лендинг успешно опубликован`
              : `Лендинг отклонен`,
        })
      );
      onSuccess(status);
    } catch (e) {
      setButtonState?.(false);
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.message,
        })
      );
    } finally {
      setComponentLoader && delayByLoader(false, setComponentLoader, 300);
    }
  };
};

export const removeLanding = (body, onSuccess, setModalShow) => {
  return async (dispatch) => {
    try {
      const response = await $api.delete(
        `/api/landing/removeLanding/?status=${body.status}&landingId=${body.id}`
      );

      dispatch(
        createNotification({
          type: "success",
          children: `Лендинг успешно удалён`,
        })
      );
      onSuccess(response.status);
    } catch (e) {
      setModalShow?.(false);
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};
