import React from "react";
import Col from "react-bootstrap/esm/Col";
import styles from "./ColumTitle.module.css";

const ColumTitle = ({ text, col, index }) => {
  return (
    <Col lg={col} md={col} style={{ paddingLeft: index === 0 ? "0px" : "" }}>
      <div className={styles.title}>{text || ""}</div>
    </Col>
  );
};

export default ColumTitle;
