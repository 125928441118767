import React from "react";
import classes from "./Button.module.css";

const Button = ({
  children,
  onClick,
  icon,
  type,
  typeOf,
  className,
  disable = false,
  ...props
}) => {
  let btnClasses = className ? `${classes.Btn} ${className}` : classes.Btn;

  switch (type) {
    case "login": {
      btnClasses += ` ${classes.Login}`;
      break;
    }
    default: {
    }
  }
  return (
    <button
      {...props}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
      type="button"
      typeof={typeOf}
      className={`${btnClasses} ${disable && classes.disabled_button}`}
    >
      {icon && <img src={icon} alt="icon" />}
      {children}
    </button>
  );
};

export default Button;
