/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentUserOp, currentUserSel } from "../../../store/currentUser";
import { landingOp, landingSel } from "../../../store/landing";
import { classes } from "../../../utils/utils";
import styles from "./LandingsTab.module.css";

const AdminTabData = [
  { type: 0, text: "Все" },
  { type: 3, text: "На модерации" },
  { type: 1, text: "Опубликованные" },
  { type: 2, text: "Не Опубликованные" },
  { type: 4, text: "В архиве" },
  { type: 5, text: "Черновики" },
];

const ModeratorTabData = [
  { type: 3, text: "На модерации" },
  { type: 1, text: "Опубликованные" },
  { type: 2, text: "Не Опубликованные" },
];

const LandingsTab = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSel.currentUser);
  const landingsData = useSelector(landingSel.landingsData);
  const selectedLandingTab = useSelector(currentUserSel.selectedLandingTab);

  const [tab, setTab] = useState(currentUser.role === 1 ? 0 : 3);

  useEffect(() => {
    dispatch(
      currentUserOp.handleSetSelectedLandingTab(currentUser.role === 1 ? 0 : 3)
    );
  }, []);

  useEffect(() => {
    setTab(selectedLandingTab);
  }, [selectedLandingTab]);

  const tabData = currentUser.role === 1 ? AdminTabData : ModeratorTabData;

  const onTabClickHandler = (item) => {
    setTab(item.type);
    dispatch(currentUserOp.handleSetSelectedLandingTab(item.type));
    dispatch(landingOp.handleSetLandingsFilteredData([]));

    if (item.type === 0) {
      dispatch(landingOp.handleSetLandingsFilteredData(landingsData));
    } else {
      const newData = landingsData?.filter((el) => {
        return +el.status === item.type;
      });

      dispatch(landingOp.handleSetLandingsFilteredData(newData));
    }
  };

  return (
    <div className={styles.container}>
      {tabData.map((item) => {
        return (
          <span
            key={item.type}
            className={classes(
              styles.item,
              tab === item.type && styles.active_item
            )}
            onClick={() => onTabClickHandler(item)}
          >
            {item.text}
          </span>
        );
      })}
    </div>
  );
};

export default LandingsTab;
