import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddUserIcon from "../../assets/icon/add_user_icon.png";
import { useWindowSize } from "../../hooks/useWindowSize";
import { classes } from "../../utils/utils";
import Button from "../ui/button/Button";
import Tab from "../ui/tab/Tab";
import styles from "./TabAndSearch.module.css";

const TabAndSearch = ({ selectedUserData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [width] = useWindowSize();

  const [buttonState] = useState(location.pathname.includes("users"));

  const onCreateNewUserHandler = () => {
    navigate("/user/create");
  };

  return (
    <div
      className={classes(
        styles.container,
        !buttonState && styles.selected_container
      )}
    >
      <Tab variant={buttonState} />
      {buttonState && (
        <Button
          className={classes(
            styles.create_user_button,
            width <= 1024 && !selectedUserData && styles.create_user_button_mob,
            width <= 1024 &&
              selectedUserData &&
              styles.create_user_button_mob_sel
          )}
          icon={AddUserIcon}
          onClick={onCreateNewUserHandler}
        >
          ДОБАВИТЬ ПОЛьЗОВАТЕЛЯ
        </Button>
      )}
    </div>
  );
};

export default TabAndSearch;
