import { createSelector } from "reselect";

const globalSelector = (state) => state.global;

const loader = createSelector([globalSelector], (global) => global.loader);

const contentLOader = createSelector(
  [globalSelector],
  (global) => global.contentLOader
);

const authLoader = createSelector(
  [globalSelector],
  (global) => global.authLoader
);

const notifications = createSelector(
  [globalSelector],
  (global) => global.notifications
);

const selectors = {
  loader,
  contentLOader,
  authLoader,
  notifications,
};

export default selectors;
