import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { currentUsersSlice } from "./currentUser/currentUsersSlice";
import { globalSlice } from "./global/globalSlice";
import { landingSlice } from "./landing/landingSlice";
import { usersSlice } from "./users/usersSlice";

export function makeStore() {
  return configureStore({
    reducer: {
      global: globalSlice.reducer,
      currentUsers: currentUsersSlice.reducer,
      users: usersSlice.reducer,
      landing: landingSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(thunkMiddleware),
  });
}

const store = makeStore();

export default store;
