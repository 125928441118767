import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./CustomLink.module.css";

const CustomLink = ({ text, to, className }) => {
  return (
    <NavLink
      style={({ isActive }) => {
        return isActive ? { color: "#333333" } : { color: "#A7A7A7" };
      }}
      to={to}
      className={styles.header_link}
    >
      {text}
    </NavLink>
  );
};

export default CustomLink;
