import React from "react";
import styles from "./StatusText.module.css";

const LANDING_STATUS_AND_STYLE = {
  1: {
    text: "Опубликован",
    style: styles.approve_container,
  },
  2: {
    text: "Не опубликован",
    style: styles.not_approve_container,
  },
  3: {
    text: "На модерации",
    style: styles.moderation_container,
  },
  4: {
    text: "В архиве",
    style: styles.in_archive,
  },
  5: {
    text: "Черновик",
    style: styles.draft,
  },
};

const StatusText = ({ status }) => {
  return (
    <div
      className={LANDING_STATUS_AND_STYLE[status]?.style}
      style={{
        minWidth: status === 2 || status === 3 ? "110px" : "",
        textAlign: "center",
      }}
    >
      <span>{LANDING_STATUS_AND_STYLE[status]?.text}</span>
    </div>
  );
};

export default StatusText;
