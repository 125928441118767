import React from "react";
import AlertCloseIcon from "../../../../assets/icon/alert-close-icon.png";

const CloseIcon = () => {
  return (
    <>
      <img src={AlertCloseIcon} alt="" />
    </>
  );
};

export default CloseIcon;
