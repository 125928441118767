import { createNotification } from "../service/app";
import { currentUserOp } from "../store/currentUser";

export const errorsArray = (data, userErrors) => {
  if (!userErrors || userErrors.length === 0) return false;

  let theFields = { ...data };
  userErrors.map((error) => {
    if (error.path in data) {
      theFields[error.path] = {
        value: data[error.path].value,
        error: error.msg,
      };
    }

    return error;
  });
  return theFields;
};

export const processError = (dispatch, error) => {
  if (error.response) {
    const response = error.response;

    if (response.status === 403) {
      dispatch(
        createNotification({
          type: "error",
          children: response.data.message,
        })
      );
    } else {
      const msgText = error.response.data.message
        ? error.response.data.message
        : "Что-то пошло не так!";
      dispatch(
        createNotification({
          type: "error",
          children: msgText,
        })
      );
    }
  } else {
    dispatch(
      createNotification({
        type: "error",
        children: error.request
          ? "Ошибка подключения"
          : "Непредвиденная ошибка",
      })
    );
  }
};

export const processErrorValidation = ({ dispatch, error, setUserErrors }) => {
  if (error.response) {
    const response = error.response;
    const errors = response.data.errors;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      dispatch(currentUserOp.handleSetErrors(errors));
    } else {
      const msgText = error.response.data.message
        ? error.response.data.message
        : "Что-то пошло не так!";

      dispatch(
        createNotification({
          type: "error",
          children: msgText,
        })
      );
    }
  } else {
    dispatch(
      createNotification({
        type: "error",
        children: error.request
          ? "Ошибка подключения"
          : "Непредвиденная ошибка",
      })
    );
  }
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
