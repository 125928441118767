import React from "react";
import HelpContent from "../../components/helpContent/HelpContent";
import styles from "./HelpPage.module.css";

const HelpPage = () => {
  return (
    <>
      <div className={styles.container}>
        <span className={styles.title}>Написать в техподдержку</span>
        <HelpContent />
      </div>
    </>
  );
};

export default HelpPage;
