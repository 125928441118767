import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommentActive from "../../../../assets/icon/comment_icon_active.png";
import CommentPassiveIcon from "../../../../assets/icon/comment_icon_passive.png";
import ApproveActive from "../../../../assets/img/landing/approve.png";
import ApprovePassive from "../../../../assets/img/landing/approve_passive.png";
import FirstRect from "../../../../assets/img/landing/first_rect.png";
import ModerationActive from "../../../../assets/img/landing/moderation_active.png";
import ModerationPassive from "../../../../assets/img/landing/moderation_passive.png";
import NotApproved from "../../../../assets/img/landing/not_approve.png";
import { LANDING_STATUS_TYPE } from "../../../../constants/tab";
import { classes } from "../../../../utils/utils";
import styles from "./EditLandingLifeCycle.module.css";

const EditLandingLifeCycle = ({ data }) => {
  const { status, comment } = data;

  const [hoverState, setHoverState] = useState(false);

  return (
    <Container className={styles.container}>
      <Row>
        <Col xs={4} className={styles.col}>
          <div
            className={classes(styles.first_element, styles.bg)}
            style={{
              backgroundImage: `url(${FirstRect})`,
            }}
          >
            <span className={styles.status_text}>Подготовка к модерации</span>
          </div>
        </Col>
        <Col xs={4} className={styles.col}>
          <div
            className={styles.bg}
            style={{
              backgroundImage: `url(${
                status === LANDING_STATUS_TYPE.MODERATION ||
                status === LANDING_STATUS_TYPE.NOT_PUBLISHED ||
                status === LANDING_STATUS_TYPE.PUBLISHED
                  ? ModerationActive
                  : ModerationPassive
              })`,
            }}
          >
            <span
              style={{
                color:
                  status === LANDING_STATUS_TYPE.MODERATION ||
                  status === LANDING_STATUS_TYPE.NOT_PUBLISHED ||
                  status === LANDING_STATUS_TYPE.PUBLISHED
                    ? "#FFFFFF"
                    : "#A7A7A7",
              }}
              className={styles.status_text}
            >
              На модерации
            </span>
          </div>
        </Col>
        <Col xs={4} className={styles.col}>
          <div
            className={styles.bg}
            style={{
              backgroundImage: `url(${
                status === LANDING_STATUS_TYPE.NOT_PUBLISHED
                  ? NotApproved
                  : status === LANDING_STATUS_TYPE.PUBLISHED
                  ? ApproveActive
                  : ApprovePassive
              })`,
            }}
          >
            <span
              style={{
                position: "relative",
                color:
                  status === LANDING_STATUS_TYPE.NOT_PUBLISHED ||
                  status === LANDING_STATUS_TYPE.PUBLISHED
                    ? "#FFFFFF"
                    : "#A7A7A7",
              }}
              className={styles.status_text}
            >
              {status === LANDING_STATUS_TYPE.NOT_PUBLISHED
                ? `Не прошел модерацию`
                : ` Прошел модерацию`}

              {comment && status === LANDING_STATUS_TYPE.NOT_PUBLISHED && (
                <>
                  <div
                    id="comment_tooltip"
                    className={styles.comment_tooltip_icon}
                    onMouseEnter={() => setHoverState(true)}
                    onMouseLeave={() => setHoverState(false)}
                  >
                    {hoverState ? (
                      <img src={CommentActive} alt="" />
                    ) : (
                      <img src={CommentPassiveIcon} alt="" />
                    )}
                  </div>
                  <ReactTooltip
                    anchorId={`comment_tooltip`}
                    place="top"
                    content={comment}
                    className={styles.comment_tooltip}
                  />
                </>
              )}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EditLandingLifeCycle;
