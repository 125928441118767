import React from "react";
import styles from "./Checkbox.module.css";

const Checkbox = ({ checked, labelName, onChange }) => {
  return (
    <label className={`${styles.checkbox} ${styles.style_b}`}>
      <input checked={checked} type="checkbox" onChange={onChange} />
      <div className={styles.checkbox__checkmark}></div>
      <div className={styles.checkbox__body}>{labelName}</div>
    </label>
  );
};

export default Checkbox;
