import { createSlice } from "@reduxjs/toolkit";
import { initialUserState } from "./initialState";

export const currentUsersSlice = createSlice({
  name: "currentUsers",
  initialState: initialUserState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setIsAuthState(state, action) {
      state.isAuth = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    updateSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setOffers(state, action) {
      state.offers = action.payload;
    },
    setSelectedLandingTab(state, action) {
      state.selectedLandingTab = action.payload;
    },
  },
});
