/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useSelector } from "react-redux";
import { currentUserSel } from "../../../store/currentUser";
import { delayByLoader } from "../../../utils/utils";
import ColumTitle from "../columTitle/ColumTitle";
import styles from "./ModeratorLandingContent.module.css";
import ModeratorLandingItem from "./moderatorLandingItem/ModeratorLandingItem";

const columnTitle = [
  {
    title: "Название",
    col: 2,
  },
  {
    title: "Статус",
    col: 2,
  },
  {
    title: "Дата и время",
    col: 2,
  },
  {
    title: "Комментарий",
    col: 5,
  },
];

const ModeratorLandingContent = ({ data, role }) => {
  const [loader, setLoader] = useState(false);

  const selectedLandingTab = useSelector(currentUserSel.selectedLandingTab);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, []);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, [selectedLandingTab]);

  return (
    <div className={styles.container}>
      <Container
        style={{
          minWidth: "100%",
          paddingLeft: "12px",
          position: "relative",
          minHeight: "14vh",
        }}
      >
        {/* {loader && <Loader />} */}
        {data.length > 0 && (
          <Row
            style={{
              borderBottom: "1px solid #D9D9D9",
              paddingBottom: "8px",
              width: "100%",
            }}
          >
            {columnTitle.map((item, i) => {
              return (
                <ColumTitle
                  key={item.title}
                  col={item.col}
                  text={item.title}
                  index={i}
                />
              );
            })}
          </Row>
        )}

        <div
          style={{
            marginBottom: 24,
          }}
        >
          {data.map((item) => {
            return (
              <Row
                key={item._id}
                style={{
                  paddingTop: "10px",
                  paddingBottom: "12px",
                  alignItems: "center",
                  width: "100%",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <ModeratorLandingItem data={item} />
              </Row>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default ModeratorLandingContent;
