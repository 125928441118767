/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddUserIcon from "../../../assets/icon/add_user_icon.png";
import ArrowUpIcon from "../../../assets/icon/arrow_up_icon.png";
import RemoveButton from "../../../assets/icon/landing_remove_icon.png";
import SaveIcon from "../../../assets/icon/save_landing_icon.png";
import SearchIcon from "../../../assets/icon/search_icon.png";
import SelectedIcon from "../../../assets/icon/search_selected_icon.png";
import AvatarIcon from "../../../assets/img/avatar.png";
import Bg from "../../../assets/img/pic.png";
import { LANDING_STATUS_TYPE } from "../../../constants/tab";
import { createLanding } from "../../../service/landing_api";
import { getAllActiveAndPublicOffers } from "../../../service/user_api";
import { currentUserSel } from "../../../store/currentUser";
import { classes, delayByLoader } from "../../../utils/utils";
import Button from "../../ui/button/Button";
import Input from "../../ui/inputs/input/Input";
import ContentLoader from "../../ui/loaders/contentLoader/ContentLoader";
import styles from "./CreateLandingContent.module.css";
import Checkbox from "../../checkbox/Checkbox";

const CreateLandingContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offers = useSelector(currentUserSel.offers);
  const currentUser = useSelector(currentUserSel.currentUser);

  const [contentLoader, setContentLoader] = useState(false);
  const [landingName, setLandingName] = useState("");
  const [nikName, setNikName] = useState("");
  const [description, setDescription] = useState("");
  const [avatarImage, setAvatarImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [productImages, setProductImage] = useState([]);
  const [offersValue, setOffersValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [offersData, setOffersData] = useState([]);
  const [offersNotExist, setOffersNotExist] = useState([]);

  const [productData, setProductData] = useState([
    {
      id: 1,
      image: false,
      title: "",
      description: "",
      link: "",
      deepLink: "",
      searchOpenState: false,
      search: {
        value: "",
      },
      offersData: {
        title: "",
        offer_id: null,
        url_preview: "",
      },
    },
    {
      id: 2,
      image: false,
      title: "",
      description: "",
      link: "",
      deepLink: "",
      searchOpenState: false,
      search: {
        value: "",
      },
      offersData: {
        title: "",
        offer_id: null,
        url_preview: "",
      },
    },
    {
      id: 3,
      image: false,
      title: "",
      description: "",
      link: "",
      deepLink: "",
      searchOpenState: false,
      search: {
        value: "",
      },
      offersData: {
        title: "",
        offer_id: null,
        url_preview: "",
      },
    },
  ]);

  const addNewProduct = () => {
    const newProduct = {
      id: Date.now(),
      image: false,
      title: "",
      description: "",
      link: "",
      deepLink: "",
      searchOpenState: false,
      search: {
        value: "",
      },
      offersData: {
        title: "",
        offer_id: null,
        url_preview: "",
      },
    };

    setProductData([...productData, newProduct]);
  };

  useEffect(() => {
    const enabledOffersIds = currentUser.enabledOffers.map(
      (item) => item.offer_id
    );

    dispatch(getAllActiveAndPublicOffers(enabledOffersIds, setOffersNotExist));
    delayByLoader(true, setContentLoader, 500);
  }, []);

  const onSetImages = (e) => {
    setAvatarImage(e.target.files[0]);

    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("avatar_image")
          .setAttribute("srcSet", e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSetCoverImages = (e) => {
    setCoverImage(e.target.files[0]);

    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("cover_image")
          .setAttribute("srcSet", e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCardChange = (index, field, value) => {
    const updatedProductData = [...productData];
    updatedProductData[index][field] = value;
    setProductData(updatedProductData);
  };

  const cardRemoveHandler = (id) => {
    const updatedProductData = productData.filter((item) => item.id !== id);
    setProductData(updatedProductData);
  };

  const onSubmitHandler = (status) => {
    const filteredProductData = productData.map((item) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        image: item.image,
        offersData: item.offersData,
        deepLink: item.deepLink,
      };
    });

    const body = {
      landingName,
      avatar: avatarImage,
      nikName,
      cover: coverImage,
      description,
      status: status,
      products: JSON.stringify(filteredProductData),
      productImages: productImages,
    };

    dispatch(createLanding(body, setContentLoader, onSuccess, status));
  };

  const onSuccess = () => {
    navigate("/landings");
  };

  const onSetProductImage = (e, id, index, field) => {
    const updatedProductImageData = [...productImages];

    updatedProductImageData[index] = e.target.files[0];
    setProductImage(updatedProductImageData);

    const updatedProductData = [...productData];
    updatedProductData[index][field] = true;

    setProductData(updatedProductData);

    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById(
          id
        ).style.backgroundImage = `url(${e.target.result})`;
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSearchHandler = (index, field, value) => {
    if (!value) {
      setOffersValue("");
      setOffersData([]);

      const updatedProductData = [...productData];
      updatedProductData[index][field].value = "";
      setProductData(updatedProductData);

      return;
    }

    const updatedProductData = [...productData];
    updatedProductData[index][field].value = value;
    setProductData(updatedProductData);

    const data = [...offers].filter((item) => {
      return (
        item.title.toLowerCase().includes(value.toLowerCase()) ||
        `${item.offer_id}`.toLowerCase().includes(value.toLowerCase())
      );
    });

    setOffersData(data);
  };

  const handleCardSearchOpenState = (index, field, value) => {
    setOffersValue("");
    setOffersData([]);
    const updatedProductData = [...productData];
    updatedProductData[index]["search"].value = "";

    updatedProductData.forEach((item) => {
      item.searchOpenState = false;
    });

    updatedProductData[index][field] = true;
    setProductData(updatedProductData);
  };

  const onSearchDataHandler = (index, field, item) => {
    const updatedProductData = [...productData];

    updatedProductData[index].deepLink = "";

    updatedProductData[index][field].title = item.title || "";
    updatedProductData[index][field].offer_id = item.offer_id || null;
    updatedProductData[index][field].allow_deeplink =
      item.allow_deeplink || false;
    updatedProductData[index][field].url_preview = item.url_preview || "";
    updatedProductData[index]["searchOpenState"] = !item.title || false;

    setProductData(updatedProductData);

    setOffersValue("");
    setOffersData([]);
  };

  const containerRef = useRef(null);

  return (
    <Container className={styles.container} ref={containerRef}>
      {contentLoader && <ContentLoader zIndex />}
      {contentLoader && (
        <div
          className={styles.container_bg}
          style={{
            height: `${
              containerRef?.current?.getBoundingClientRect()?.height * 1.15
            }px`,
          }}
        ></div>
      )}
      <Row className={styles.first_section}>
        <Col lg={4} md={4} sm={12} className={styles.col}>
          <div className={styles.first_section_content}>
            <span className={styles.title}>Имя лендинга</span>
          </div>
          <Input
            className={styles.input}
            type="text"
            placeholder="Введите Имя"
            value={landingName}
            setValue={setLandingName}
          />
        </Col>
        <Col
          lg={8}
          md={8}
          sm={12}
          className={classes(styles.col, styles.buttons, styles.bottom_buttons)}
        >
          <Button
            onClick={() => onSubmitHandler(LANDING_STATUS_TYPE.DRAFT)}
            icon={SaveIcon}
            className={styles.view_button}
          >
            <span className={styles.button_text}>сохранить черновик</span>
          </Button>
          <Button
            onClick={() => onSubmitHandler(LANDING_STATUS_TYPE.MODERATION)}
            className={styles.lunch_button}
          >
            Опубликовать страницу
          </Button>
        </Col>
      </Row>
      <Row className={styles.hero_section}>
        <Col lg={12} md={12} sm={12} className={styles.col}>
          <span className={styles.hero_section_title}>
            Обложка (Hero Section)
          </span>
        </Col>
      </Row>
      <Row className={styles.second_section}>
        <Col lg={4} md={4} sm={12} className={styles.col}>
          <span className={styles.second_section_title}>
            Аватар (Userpic image)
          </span>
          <div className={styles.second_section_content}>
            <div className={styles.second_section_content_avatar}>
              <img id="avatar_image" src={AvatarIcon} alt="" />
            </div>
            <div className={styles.second_section_content_button}>
              <div className={styles.second_section_content_button_wrapper}>
                <Button
                  onClick={() => null}
                  className={styles.add_avatar_button}
                >
                  <label
                    className={styles.file_input_label}
                    htmlFor="file-input-avatar"
                  >
                    загрузить изображение
                  </label>
                </Button>
                <input
                  accept="image/*"
                  id="file-input-avatar"
                  type="file"
                  name="file"
                  onChange={onSetImages}
                  className={styles.file_input}
                />
              </div>
              <span>Рекомендованный размер 320х320 px</span>
            </div>
          </div>
          <div className={styles.second_section_input_content}>
            <span className={styles.title}>Имя/Nikname</span>
            <Input
              className={styles.input}
              type="text"
              placeholder="Введите никнейм"
              value={nikName}
              setValue={setNikName}
            />
          </div>
        </Col>
        <Col
          lg={8}
          md={8}
          sm={12}
          className={classes(styles.col, styles.second_section_bg)}
        >
          <span className={styles.title}>Фоновое изображение (Bg image):</span>
          <div className={styles.second_section_bg_content}>
            <div className={styles.second_section_bg_content_img}>
              <img id="cover_image" src={Bg} alt="" />
            </div>
            <div className={styles.button}>
              <Button onClick={() => null} className={styles.add_avatar_button}>
                <label
                  className={styles.file_input_label}
                  htmlFor="file-input-cover"
                >
                  загрузить изображение
                </label>
              </Button>
              <input
                accept="image/*"
                id="file-input-cover"
                type="file"
                name="file"
                onChange={onSetCoverImages}
                className={styles.file_input}
              />
              <span className={styles.title}>
                Рекомендованный размер 3840х1086 px
              </span>
            </div>
          </div>
          <div className={styles.input_section}>
            <span className={styles.title}>Заголовок</span>
            <Input
              className={styles.input}
              typeInput="text"
              type="text"
              placeholder="Описание страницы"
              value={description}
              setValue={setDescription}
            />
          </div>
        </Col>
      </Row>
      <Row className={styles.product_section}>
        <Col lg={12} md={12} sm={12} className={styles.col}>
          <span className={styles.product_section_title}>
            Товары (Products)
          </span>
        </Col>
      </Row>
      <Row className={styles.third_section}>
        {productData.map((item, index) => {
          return (
            <Col key={item.id} lg={4} md={6} sm={12} xs={12}>
              <div className={styles.content}>
                <div className={styles.content_header}>
                  <span className={styles.content_header_title}>
                    {`Карточка товара #${index + 1}`}
                  </span>
                  <img
                    onClick={() => cardRemoveHandler(item.id)}
                    className={styles.remove_icon}
                    src={RemoveButton}
                    alt=""
                  />
                </div>
                <label
                  className={styles.file_input_label}
                  htmlFor={`product_img_${index}`}
                >
                  <div
                    id={`product_img_prev_${index}`}
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${Bg})`,
                      position: "relative",
                    }}
                  >
                    {!item.image && (
                      <Button
                        onClick={() => null}
                        className={styles.view_button}
                      >
                        <label
                          className={styles.file_input_label}
                          htmlFor={`product_img_${index}`}
                        >
                          загрузить
                        </label>
                      </Button>
                    )}
                    <input
                      accept="image/*"
                      id={`product_img_${index}`}
                      type="file"
                      name={`product_img_${index}`}
                      onChange={(e) => {
                        onSetProductImage(
                          e,
                          `product_img_prev_${index}`,
                          index,
                          "image"
                        );
                      }}
                      style={{
                        position: "absolute",
                        top: "10%",
                        left: "-50%",
                        opacity: 0,
                        cursor: "pointer",
                        padding: 20,
                      }}
                    />
                  </div>
                </label>

                <div className={styles.inputs}>
                  <div className={styles.input_content}>
                    <span className={styles.title}>Заголовок:</span>
                    <input
                      className={styles.card_input}
                      type="text"
                      placeholder="Введите текст"
                      value={item.title}
                      onChange={(e) =>
                        handleCardChange(index, "title", e.target.value)
                      }
                    />
                  </div>
                  <div className={styles.input_content}>
                    <span className={styles.title}>Описание:</span>
                    <input
                      className={styles.card_input}
                      type="text"
                      placeholder="Введите текст"
                      value={item.description}
                      onChange={(e) =>
                        handleCardChange(index, "description", e.target.value)
                      }
                    />
                  </div>

                  <div
                    className={classes(
                      styles.input_content,
                      styles.input_content_search
                    )}
                  >
                    <span className={styles.title}>Поиск:</span>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        className={styles.card_input}
                        type="text"
                        placeholder="Введите ID или название оффера"
                        value={item.offersData.title}
                        readOnly
                        onClick={() => {
                          handleCardSearchOpenState(
                            index,
                            "searchOpenState",
                            true
                          );
                        }}
                      />
                      <img
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: 10,
                          transform: "translateY(-50%) rotate(180deg)",
                          cursor: "pointer",
                        }}
                        src={ArrowUpIcon}
                        onClick={() => {
                          handleCardSearchOpenState(
                            index,
                            "searchOpenState",
                            true
                          );
                        }}
                        alt=""
                      />
                    </div>
                    {item.searchOpenState && (
                      <div className={styles.search_component}>
                        <div className={styles.search_component_header}>
                          <span
                            className={styles.search_component_header_title}
                          >
                            Введите ID или название оффера
                          </span>
                          <img
                            src={ArrowUpIcon}
                            onClick={() => {
                              handleCardChange(index, "searchOpenState", false);
                            }}
                            alt=""
                          />
                        </div>
                        <div className={styles.search_component_block}>
                          <div
                            style={{
                              width: "100%",
                              position: "relative",
                              height: "fit-content",
                            }}
                          >
                            <input
                              className={classes(
                                styles.card_input,
                                styles.search_input
                              )}
                              value={item.search.value}
                              onChange={(e) =>
                                onSearchHandler(index, "search", e.target.value)
                              }
                              type="text"
                              placeholder="Найти"
                              autoFocus
                            />
                            <img
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              }}
                              src={SearchIcon}
                              alt=""
                            />
                          </div>
                          {offersNotExist?.title && (
                            <>
                              <Checkbox
                                checked={item.offersData.offer_id === "1007"}
                                onChange={() => {
                                  if (item.offersData.offer_id === "1007") {
                                    onSearchDataHandler(
                                      index,
                                      "offersData",
                                      {}
                                    );
                                  } else {
                                    onSearchDataHandler(
                                      index,
                                      "offersData",
                                      offersNotExist
                                    );
                                  }
                                }}
                                labelName={offersNotExist?.title}
                              />
                              <div
                                style={{
                                  width: "100%",
                                  borderBottom: "1px solid #ECECEC",
                                  marginBottom: "10px",
                                }}
                              ></div>
                            </>
                          )}
                          <div
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            {offersData.length > 0 &&
                              offersData.map((el, i) => {
                                return (
                                  i <= 5 && (
                                    <div
                                      className={
                                        styles.search_component_block_item
                                      }
                                      key={el.offer_id}
                                    >
                                      <p
                                        onClick={(e) => {
                                          onSearchDataHandler(
                                            index,
                                            "offersData",
                                            el
                                          );
                                        }}
                                        style={{
                                          color: "#000",
                                          margin: 0,
                                          padding: "10px 5px",
                                          fontSize: "13px",
                                          fontFamily: "Roboto",
                                          cursor: "pointer",
                                          pointerEvents:
                                            el.status === "active" &&
                                            item?.offersData?.offer_id !==
                                              el.offer_id
                                              ? "unset"
                                              : el.status === "active" &&
                                                item?.offersData?.offer_id ===
                                                  el.offer_id
                                              ? "none"
                                              : "none",
                                          opacity:
                                            el.status === "active" ? 1 : 0.5,
                                        }}
                                      >
                                        {`${el.title} ID: ${el.offer_id} `}
                                      </p>
                                      {item?.offersData?.offer_id ===
                                        el.offer_id && (
                                        <img
                                          style={{
                                            marginLeft: "20px",
                                          }}
                                          width={20}
                                          height={20}
                                          src={SelectedIcon}
                                          alt=""
                                        />
                                      )}
                                      {el.status !== "active" && (
                                        <span
                                          className={styles.status_text}
                                        >{`${
                                          el.status === "stopped"
                                            ? "приостановлен"
                                            : el.status === "suspended"
                                            ? "отключён"
                                            : ""
                                        }`}</span>
                                      )}
                                    </div>
                                  )
                                );
                              })}
                          </div>
                          {offersData.length === 0 &&
                            item.search.value.length > 0 && (
                              <span
                                className={styles.search_component_block_title}
                              >
                                Ничего не найдено
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  {item.offersData.allow_deeplink && (
                    <div className={styles.input_content}>
                      <span className={styles.title}>Прямая ссылка:</span>
                      <input
                        className={styles.card_input}
                        type="text"
                        placeholder="Введите ссылку"
                        value={item.deepLink}
                        onChange={(e) =>
                          handleCardChange(index, "deepLink", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          );
        })}

        <Col lg={4} md={6} sm={12} xs={12}>
          <div className={classes(styles.content, styles.add_content)}>
            <Button
              className={classes(styles.create_new_card)}
              icon={AddUserIcon}
              onClick={addNewProduct}
            >
              Добавить карточку товара
            </Button>
          </div>
        </Col>
      </Row>
      <Row className={styles.bottom_buttons}>
        <Col
          sm={12}
          className={classes(
            styles.col,
            styles.buttons,
            styles.lunch_button_bottom
          )}
        >
          <Button
            onClick={() => onSubmitHandler(LANDING_STATUS_TYPE.DRAFT)}
            icon={SaveIcon}
            className={styles.view_button}
          >
            <span className={styles.button_text}>сохранить черновик</span>
          </Button>

          <Button
            onClick={() => onSubmitHandler(LANDING_STATUS_TYPE.MODERATION)}
            className={classes(styles.lunch_button)}
          >
            Опубликовать страницу
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateLandingContent;
