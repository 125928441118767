import { createSelector } from "reselect";

const usersSelector = (state) => state.users;

const usersListData = createSelector(
  [usersSelector],
  (users) => users.usersListData
);

const filteredUsersList = createSelector(
  [usersSelector],
  (users) => users.filteredUsersList
);

const selectedUser = createSelector(
  [usersSelector],
  (users) => users.selectedUser
);

const activeTab = createSelector([usersSelector], (users) => users.activeTab);

const selectors = {
  usersListData,
  filteredUsersList,
  selectedUser,
  activeTab,
};

export default selectors;
