import { createSelector } from "reselect";

const userSelector = (state) => state.currentUsers;

const currentUser = createSelector(
  [userSelector],
  (currentUser) => currentUser.currentUser
);

const isAuth = createSelector(
  [userSelector],
  (currentUser) => currentUser.isAuth
);

const errors = createSelector(
  [userSelector],
  (currentUser) => currentUser.errors
);

const selectedTab = createSelector(
  [userSelector],
  (currentUser) => currentUser.selectedTab
);

const offers = createSelector(
  [userSelector],
  (currentUser) => currentUser.offers
);

const selectedLandingTab = createSelector(
  [userSelector],
  (currentUser) => currentUser.selectedLandingTab
);

const selectors = {
  currentUser,
  isAuth,
  errors,
  selectedTab,
  offers,
  selectedLandingTab,
};

export default selectors;
