import React from "react";
import { useSelector } from "react-redux";
import CreateLandingContent from "../../components/landingPage/createLandingContent/CreateLandingContent";
import LandingLifeCycle from "../../components/landingPage/landingLifeCycle/LandingLifeCycle";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import PageTitle from "../../components/ui/pageTitle/PageTitle";
import { globalSel } from "../../store/global";
import styles from "./CreateLandingPage.module.css";

const CreateLandingPage = () => {
  const contentLOader = useSelector(globalSel.contentLOader);

  return (
    <>
      {contentLOader && <ContentLoader top={54} zIndex />}
      <div className={styles.container}>
        <PageTitle title="Создание лендинга" />
        <LandingLifeCycle />
        <CreateLandingContent />
      </div>
    </>
  );
};

export default CreateLandingPage;
