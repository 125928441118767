import { globalSlice } from "./globalSlice";

const {
  setLoader,
  setContentLoader,
  addNotification,
  removeNotification,
  setAuthLoader,
} = globalSlice.actions;

const handleLoaderState = (state) => (dispatch) => {
  dispatch(setLoader(state));
};

const handleSetContentLoader = (state) => (dispatch) => {
  dispatch(setContentLoader(state));
};

const handleAddNotification = (state) => (dispatch) => {
  dispatch(addNotification(state));
};

const handleRemoveNotification = (state) => (dispatch) => {
  dispatch(removeNotification(state));
};

const handleSetAuthLoader = (state) => (dispatch) => {
  dispatch(setAuthLoader(state));
};

const operations = {
  handleLoaderState,
  handleSetContentLoader,
  handleAddNotification,
  handleRemoveNotification,
  handleSetAuthLoader,
};

export default operations;
