/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyLanding from "../../components/landingPage/emptyLanding/EmptyLanding";
import ModeratorLandingContent from "../../components/landingPage/moderatorLandingContent/ModeratorLandingContent";
import ModeratorLandingMobileContent from "../../components/landingPage/moderatorLandingMobileContent/ModeratorLandingMobileContent";
import LandingsTab from "../../components/ui/landingsTab/LandingsTab";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import Loader from "../../components/ui/loaders/loader/Loader";
import { LANDING_STATUS_TYPE } from "../../constants/tab";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getAllLanding } from "../../service/landing_api";
import { currentUserSel } from "../../store/currentUser";
import { landingSel } from "../../store/landing";
import { delayByLoader } from "../../utils/utils";
import styles from "./ModeratorLandingPage.module.css";

const ModeratorLandingPage = () => {
  const [width] = useWindowSize();
  const dispatch = useDispatch();

  const [componentLoader, setComponentLoader] = useState(true);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(3);

  const landingsData = useSelector(landingSel.landingsData);
  const landingsFilteredData = useSelector(landingSel.landingsFilteredData);
  const currentUser = useSelector(currentUserSel.currentUser);
  const selectedLandingTab = useSelector(currentUserSel.selectedLandingTab);

  useEffect(() => {
    setTab(selectedLandingTab);
  }, [selectedLandingTab]);

  useEffect(() => {
    if (landingsData.length > 0) {
      setData(
        currentUser.role === 1
          ? landingsData
          : landingsData
              ?.filter((item) => {
                return (
                  +item.status === LANDING_STATUS_TYPE.PUBLISHED ||
                  +item.status === LANDING_STATUS_TYPE.MODERATION
                );
              })
              .filter((item) => item.status === tab)
      );
    }
  }, [landingsData, currentUser]);

  useEffect(() => {
    setData([]);
    const data = [...landingsData];

    if (tab === 0) {
      setData(landingsData);
    } else {
      if (data.length > 0) {
        setData(data.filter((item) => +item.status === tab));
      } else {
        setData([]);
      }
    }
  }, [tab]);

  useEffect(() => {
    setData([]);
    const data = [...landingsData];
    setData(data.filter((item) => item.status === tab));
  }, []);

  useEffect(() => {
    dispatch(getAllLanding(currentUser.role));
    delayByLoader(true, setComponentLoader, 300);
  }, []);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, [selectedLandingTab]);

  return (
    <>
      {componentLoader && <ContentLoader top={60} zIndex />}
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Модерация</h2>
        </div>
        <LandingsTab />
        <div
          style={{
            position: "relative",
          }}
        >
          {loader && <Loader />}
          {landingsFilteredData?.length === 0 && data.length === 0 ? (
            <EmptyLanding />
          ) : landingsFilteredData?.length > 0 ? (
            width < 992 ? (
              <ModeratorLandingMobileContent data={data} />
            ) : (
              <ModeratorLandingContent data={data} />
            )
          ) : landingsData.length > 0 ? (
            width < 992 ? (
              <ModeratorLandingMobileContent data={data} />
            ) : (
              <ModeratorLandingContent data={data} />
            )
          ) : (
            <EmptyLanding />
          )}
        </div>
      </div>
    </>
  );
};

export default ModeratorLandingPage;
