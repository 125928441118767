import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import Button from "../../components/ui/button/Button";
import Input from "../../components/ui/inputs/input/Input";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { errorsArray } from "../../core/helper";
import { login } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import { delayByLoader } from "../../utils/utils";
import styles from "./Login.module.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentLoader, setContentLoader] = useState(true);

  const [data, setData] = useState({
    email: { value: "", error: false },
    password: { value: "", error: false },
  });

  useEffect(() => {
    delayByLoader(true, setContentLoader, 200);
  }, []);

  const isAuth = useSelector(currentUserSel.isAuth);
  const userErrors = useSelector(currentUserSel.errors);

  useEffect(() => {
    if (data.password.value.length >= 3) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [data]);

  useEffect(() => {
    let theFields = errorsArray(data, userErrors);
    if (theFields) {
      setData({ ...theFields });
    }
  }, [userErrors, data]);

  if (isAuth) {
    return (
      <Navigate
        to={{
          pathname: "/landings",
        }}
      />
    );
  }

  return (
    <div className={styles.Login}>
      {contentLoader && <ContentLoader />}
      <div className={styles.Logo}>
        <img src={Logo} alt="x-partners logo" />
      </div>
      <div className={styles.Content}>
        {loading && <ContentLoader bg />}
        <h1 className={styles.Title}>Authorization</h1>
        <div className={styles.InputBlock}>
          <Input
            className={styles.Input}
            typeInput="authorization"
            type="email"
            label="E-MAIL"
            placeholder="E-mail"
            required={true}
            value={data.email.value}
            setValue={(val) => {
              setData({ ...data, email: { value: val, error: false } });
            }}
            error={data.email.error}
          />
          <Input
            className={styles.Input}
            typeInput="authorization"
            type="password"
            label="password"
            placeholder="Password"
            required={true}
            value={data.password.value}
            setValue={(val) => {
              setData({ ...data, password: { value: val, error: false } });
            }}
            error={data.password.error}
            minLength={8}
            maxLength={40}
          />
          <div className={styles.FootBlock}>
            {valid && (
              <Button
                className={styles.login_button}
                onClick={() => {
                  dispatch(
                    login(data.email.value, data.password.value, setLoading)
                  );
                }}
              >
                Sign in
              </Button>
            )}

            <Link to="/registration">Create Account</Link>
          </div>
          <div className={styles.login_links_content}>
            <div
              className={styles.recoverButton}
              onClick={() => {
                navigate("/recover");
              }}
            >
              <p className={styles.login_text}>Password Recovering</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
