import { createSlice } from "@reduxjs/toolkit";
import { initialLandingState } from "./initialState";

export const landingSlice = createSlice({
  name: "landing",
  initialState: initialLandingState,
  reducers: {
    setLandingsData(state, action) {
      state.landingsData = action.payload;
    },
    setLandingsFilteredData(state, action) {
      state.landingsFilteredData = action.payload;
    },
    setLandingSingleData(state, action) {
      state.landingSingleData = action.payload;
    },
  },
});
