import { createSelector } from "reselect";

const lendingSelector = (state) => state.landing;

const landingsData = createSelector(
  [lendingSelector],
  (global) => global.landingsData
);

const landingsFilteredData = createSelector(
  [lendingSelector],
  (global) => global.landingsFilteredData
);

const landingSingleData = createSelector(
  [lendingSelector],
  (global) => global.landingSingleData
);

const selectors = {
  landingsData,
  landingsFilteredData,
  landingSingleData,
};

export default selectors;
