import React from "react";
import { useSelector } from "react-redux";
import { globalSel } from "../../store/global";
import classes from "./Notifications.module.css";
import Notification from "./notification/Notification";

const Notifications = () => {
  const notifications = useSelector(globalSel.notifications);

  return (
    <div className={classes.Notifications}>
      {notifications &&
        notifications?.map((notification) => {
          return (
            <Notification
              id={notification.id}
              key={notification.id}
              type={notification.type}
              className={notification.className}
            >
              {notification.children}
            </Notification>
          );
        })}
    </div>
  );
};

export default Notifications;
