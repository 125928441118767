import moment from "moment";
import { ACTIVE_TAB, ROLE, USERS_ROLE } from "../constants/tab";
import { createNotification } from "../service/app";

export const classes = (...rest) => {
  return [...rest]?.join(" ");
};

export const getRoleText = (role) => {
  switch (true) {
    case USERS_ROLE[role] === ROLE.ADMIN:
      return "Администратор";
    case USERS_ROLE[role] === ROLE.MODERATOR:
      return "Модератор";

    default:
      return "";
  }
};

export const onStartLoader = (fn) => {
  fn(true);
  setTimeout(() => {
    fn(false);
  }, 300);
};

export const validatePassword = (password) => {
  const passwordRegex = /^.{8,40}$/;
  return passwordRegex.test(password);
};

export const validateText = (text) => {
  const passwordRegex = /^.{2,20}$/;
  return passwordRegex.test(text);
};

export const validateEmailText = (text) => {
  const passwordRegex = /^.{2,500}$/;
  return passwordRegex.test(text);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const delayByLoader = async (isTwo, fn, delay = 500) => {
  isTwo && fn?.(true);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fn?.(false);
      resolve();
    }, delay);
  });
};

export const getTabNumber = (selectedTab) => {
  return selectedTab === ACTIVE_TAB.ADMINS ? 1 : 2;
};

export const dispatchErrorNotification = (dispatch, message) => {
  dispatch(
    createNotification({
      type: "error",
      children: message,
    })
  );
};

export const removeCharsAndAddEllipsis = (str, numChars = 12) => {
  if (str.length <= numChars) {
    return str;
  } else {
    return str.slice(0, numChars) + "...";
  }
};

export const landingFormatDate = (dateString) => {
  const formattedDate = moment(dateString).format("DD.MM.YYYY HH:mm:ss");
  return formattedDate;
};

export const fetchImageAsFile = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const filename = getFilenameFromUrl(url);
      const file = new File([blob], filename, { type: blob.type });
      return file;
    });
};

function getFilenameFromUrl(url) {
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 1];
}

export const extractURL = (url) => {
  url = url.replace("/view", "").replace(".html", "");

  return url;
};
