import React, { useState, useEffect } from "react";
import classes from "./Input.module.css";
import InputError from "../../message/inputError/InputError";

const Input = ({
  value,
  setValue,
  required,
  placeholder,
  label,
  type,
  name,
  error,
  disabled,
  id,
  typeInput,
  className,
}) => {
  const [focused, setFocused] = useState(!!value);

  const onFocus = () => setFocused(true);
  const onBlur = (e) => !e.target.value && setFocused(false);

  const onChange = (e) => {
    setFocused(!!e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    setFocused(!!value);
  }, [value]);

  const inputClasses = [classes.InputUi];

  if (className) {
    inputClasses.push(className);
  }

  if (typeInput === "authorization") {
    inputClasses.push(classes.Authorization);
  }

  return (
    <div className={inputClasses.join(" ")}>
      {label && (
        <label htmlFor={id}>
          {label} {required ? <span className={classes.Required}>*</span> : ""}
        </label>
      )}
      <input
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value || ""}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        focused={focused.toString()}
        name={name}
      />
      {error && <InputError error={error} />}
    </div>
  );
};

export default Input;
