import { landingSlice } from "./landingSlice";

const { setLandingsData, setLandingsFilteredData, setLandingSingleData } =
  landingSlice.actions;

const handleSetLandingsData = (data) => (dispatch) => {
  dispatch(setLandingsData(data));
};

const handleSetLandingsFilteredData = (data) => (dispatch) => {
  dispatch(setLandingsFilteredData(data));
};

const handleSetLandingSingleData = (data) => (dispatch) => {
  dispatch(setLandingSingleData(data));
};

const operations = {
  handleSetLandingsData,
  handleSetLandingsFilteredData,
  handleSetLandingSingleData,
};

export default operations;
