import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../ui/button/Button";
import styles from "./UserRemoveModal.module.css";

const UserRemoveModal = (props) => {
  const { removePrivilegedUser, ...rest } = props;

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4 className={styles.title}>
          Вы уверены, что хотите удалить пользователя
          {` ${props?.data?.name} ${props?.data?.surname} `}
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props?.onHide} className={styles.cancel_user_button}>
          ОТМЕНИТЬ
        </Button>
        <Button
          onClick={removePrivilegedUser}
          className={styles.remove_user_button}
        >
          УДАЛИТЬ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserRemoveModal;
