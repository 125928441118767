import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import Button from "../../components/ui/button/Button";
import Input from "../../components/ui/inputs/input/Input";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { errorsArray } from "../../core/helper";
import { updatePassword } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import {
  delayByLoader,
  dispatchErrorNotification,
  validatePassword,
} from "../../utils/utils";
import styles from "./UpdatePasswordPage.module.css";

const UpdatePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, recoverId } = useParams();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const userErrors = useSelector(currentUserSel.errors);

  useEffect(() => {
    delayByLoader(true, setLoading, 200);
  }, []);

  useEffect(() => {
    let theFields = errorsArray(password, userErrors);
    if (theFields) {
      setPassword({ ...theFields });
    }
  }, [password, userErrors]);

  const onRecoveringPasswordHandler = () => {
    if (!validatePassword(password)) {
      dispatchErrorNotification(
        dispatch,
        `Длина пароля должна быть не меньше 8 и не больше 40 символов`
      );
    } else {
      dispatch(updatePassword(id, recoverId, password, setLoading, navigate));
    }
  };

  return (
    <div className={styles.container}>
      {loading && <ContentLoader zIndex />}
      <div className={styles.Logo}>
        <img src={Logo} alt="x-partners logo" />
      </div>
      <div className={styles.Content}>
        <h1 className={styles.Title}>Update Password</h1>
        <div className={styles.InputBlock}>
          <Input
            type="password"
            typeInput="password"
            label="New Password"
            placeholder="Введите новый пароль"
            required={true}
            value={password}
            setValue={(val) => setPassword(val)}
          />
          <div className={styles.FootBlock}>
            <Button
              onClick={onRecoveringPasswordHandler}
              className={styles.registration_button}
            >
              Recover
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;
