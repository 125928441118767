import React from "react";
import classes from "./AppContent.module.css";

const AppContent = ({ children, ...props }) => {
  return (
    <div className={classes.App} {...props}>
      <div className={classes.Content}>{children}</div>
    </div>
  );
};

export default AppContent;
