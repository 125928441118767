import React from "react";
import CloseIcon from "../../../ui/icons/closeIcon/CloseIcon";
import classes from "./NotificationContent.module.css";

const NotificationContent = ({ type, children, onClick }) => {
  let styles = [classes.notificationContent];
  let content = "";

  switch (type) {
    case "success": {
      styles.push(classes.primary);
      content = (
        <>
          <div className={classes.close}>
            <span
              className={classes.notificationClose}
              onClick={() => {
                onClick();
              }}
            >
              <CloseIcon color="#ffffff" />
            </span>
          </div>
          <div className={classes.content}>{children}</div>
        </>
      );
      break;
    }

    case "error": {
      styles.push(classes.error);
      content = (
        <>
          <div className={classes.close}>
            <span
              className={classes.notificationClose}
              onClick={() => {
                onClick();
              }}
            >
              <CloseIcon color="#ffffff" />
            </span>
          </div>
          <div className={classes.content}>{children}</div>
        </>
      );
      break;
    }

    case "message": {
      styles.push(classes.message);
      content = (
        <div className={classes.inner}>
          <div className={classes.content}>
            {children}
            <span
              className={classes.notificationClose}
              onClick={() => {
                onClick();
              }}
            >
              <CloseIcon color="#ffffff" />
            </span>
          </div>
        </div>
      );
      break;
    }

    default: {
      styles.push(classes.message);
      content = (
        <div className={classes.inner}>
          <div className={classes.content}>
            {children}
            <span
              className={classes.notificationClose}
              onClick={() => {
                onClick();
              }}
            >
              <CloseIcon color="#ffffff" />
            </span>
          </div>
        </div>
      );
      break;
    }
  }

  return <div className={`${styles?.join(" ")}`}>{content}</div>;
};

export default NotificationContent;
