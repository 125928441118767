import React from "react";
import LeftArrowIcon from "../../../assets/icon/left_arrow.svg";
import styles from "./GoToList.module.css";

const GoToList = ({ setIsOpen }) => {
  const onGoToList = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.container} onClick={onGoToList}>
      <img src={LeftArrowIcon} alt="" />
      <span>К списку</span>
    </div>
  );
};

export default GoToList;
