import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../ui/button/Button";
import styles from "./LandingRemoveModal.module.css";

const LandingRemoveModal = (props) => {
  const { data, show, onHide, removeHandler } = props;

  return (
    <Modal
      onHide={() => onHide()}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4 className={styles.title}>
          Вы уверены, что хотите удалить лендинг
          <br />
          {`${data?.title}`}
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props?.onHide} className={styles.cancel_user_button}>
          ОТМЕНИТЬ
        </Button>
        <Button onClick={removeHandler} className={styles.remove_user_button}>
          УДАЛИТЬ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LandingRemoveModal;
