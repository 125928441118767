import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CommentIcon from "../../../../assets/icon/comment_icon.png";
import CopyIcon from "../../../../assets/icon/copy_icon.png";
import EditIcon from "../../../../assets/icon/landing_edit_icon.png";
import ShowIcon from "../../../../assets/icon/landing_eye_icon.png";
import RemoveIcon from "../../../../assets/icon/landing_remove_icon.png";
import { LANDING_STATUS_TYPE } from "../../../../constants/tab";
import { classes, delayByLoader, extractURL } from "../../../../utils/utils";
import StatusText from "../../statusText/StatusText";
import styles from "./LandingMobileItem.module.css";

const LandingMobileItem = (props) => {
  const navigate = useNavigate();

  const { _id, landingName, publishedLink, link, status, comment } = props.data;

  const [isCopy, setIsCopy] = useState(false);

  const pbLink = extractURL(publishedLink);

  const onShowView = () => {
    window.open(link, "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.first_content_wrapper}>
        <div className={styles.first_content}>
          <div className={classes(styles.word_size_control)}>
            <span className={styles.title}>{landingName}</span>
          </div>
          <StatusText status={status} />
          {comment && (
            <div className={styles.comment_icon}>
              <img id={`app-title-${props.index}`} src={CommentIcon} alt="" />
            </div>
          )}
          <ReactTooltip
            anchorId={`app-title-${props.index}`}
            place="top"
            content={comment}
            className={styles.landing_mobile_item_tooltip}
          />
        </div>
        <div className={styles.buttons}>
          <img
            onClick={onShowView}
            className={classes(styles.edit_button, styles.show_button)}
            src={ShowIcon}
            alt=""
          />
          <img
            onClick={() => {
              navigate(`/editLanding/${_id}`);
            }}
            className={styles.edit_button}
            src={EditIcon}
            alt=""
          />
          <img
            onClick={() => {
              props.onSetModalShow(_id, landingName);
            }}
            style={{
              pointerEvents:
                status === LANDING_STATUS_TYPE.ARCHIVED ? "none" : "unset",
              opacity: status === LANDING_STATUS_TYPE.ARCHIVED ? 0.4 : 1,
            }}
            className={styles.remove_button}
            src={RemoveIcon}
            alt=""
          />
        </div>
      </div>
      <div className={styles.second_content_wrapper}>
        <span className={styles.link_title}>Ссылка</span>
        <div className={styles.second_content}>
          <div
            className={classes(styles.link, isCopy && styles.copy_animation)}
          >
            <div className={styles.word_size_control}>
              {(status === 1 && pbLink) ||
                (status === 3 && pbLink && (
                  <span className={styles.link_text}>
                    {extractURL(publishedLink)}
                  </span>
                ))}
            </div>
          </div>
          <div
            className={styles.link_icon}
            onClick={() => {
              delayByLoader(true, setIsCopy, 1000);
              navigator.clipboard.writeText(extractURL(publishedLink));
            }}
          >
            <img src={CopyIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingMobileItem;
