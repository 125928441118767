/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import Bg from "../../assets/img/landing/bg.png";
import Avatar from "../../assets/img/landing/landing_default_avatar.png";
import { getLandingById } from "../../service/landing_api";
import { landingSel } from "../../store/landing";
import "./ViewLandingPage.css";

const ViewLandingPage = () => {
  const dispatch = useDispatch();
  const { landingId } = useParams();

  const landingSingleData = useSelector(landingSel.landingSingleData);

  useEffect(() => {
    dispatch(getLandingById(landingId));

    const button = document.getElementById("top_button");

    window.onscroll = function () {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        button.style.display = "inline-block";
        // button.style.justifyContent = "center";
        // button.style.alignItems = "center";
      } else {
        button.style.display = "none";
      }
    };

    button.addEventListener("click", function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });

    const rows = Math.ceil(document.querySelectorAll(".view_item").length / 3);

    for (var i = 0; i < rows; i++) {
      const rowBlocks = document.querySelectorAll(
        ".view_item:nth-child(n+" +
          (i * 3 + 1) +
          "):nth-child(-n+" +
          (i + 1) * 3 +
          ")"
      );
      let maxHeight = 0;

      rowBlocks.forEach((block) => {
        if (block.offsetHeight > maxHeight) {
          maxHeight = block.offsetHeight;
        }
      });

      rowBlocks.forEach(function (block) {
        block.style.height = maxHeight + "px";
      });
    }
  }, []);

  return (
    <div className="view_container">
      <div
        className="view_header"
        style={{
          backgroundImage: `url(${landingSingleData.cover || Bg})`,
        }}
      >
        <div className="bg_color"></div>
        <div className="avatar">
          <img
            src={landingSingleData.avatar ? landingSingleData.avatar : Avatar}
            alt=""
          />
        </div>
        <span className="view_nikname_text">{landingSingleData.nikName}</span>
        <span className="view_description_text">
          {landingSingleData.description}
        </span>
      </div>
      <div className="view_content">
        {landingSingleData?.products?.map((item) => {
          return item.image && <Item key={item._id} data={item} />;
        })}
      </div>
      <div className="top_button">
        <button id="top_button" className="btn-style700">
          Back to top
        </button>
      </div>
    </div>
  );
};

export default ViewLandingPage;

const Item = ({ data }) => {
  return (
    <NavLink
      to={`${data.link}`}
      target="_blank"
      className="view_item"
      // onClick={() => {
      //   data.link && window.open(data.link, "_blank");
      // }}
      style={{ cursor: data.link ? "pointer" : "unset" }}
    >
      <div
        className="view_product_image"
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        {/* <img src={data.image} alt="" /> */}
      </div>
      <div className="view_product_text">
        <span className="view_product_title_text word_size_control">
          {data.title}
        </span>
        <span className="view_product_description_text word_size_control">
          {data.description}
        </span>
      </div>
    </NavLink>
  );
};
