import React from "react";
import logoutIcon from "../../../assets/icon/logout-icon.svg";
import { ROLE, USERS_ROLE } from "../../../constants/tab";
import CustomLink from "../../ui/customLink/CustomLink";
import styles from "./MobileNavbar.module.css";

const MobileNavbar = ({
  onLogoutHandler,
  managerEmail,
  setMenuState,
  email,
  role,
}) => {
  const handleClick = () => {
    setMenuState(false);
  };

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.navbar_mobile} onClick={handleInnerClick}>
        <CustomLink text="ЛЕНДИНГИ" to="/landings" />
        {USERS_ROLE[role] === ROLE.WEB_MASTER && (
          <CustomLink text="Помощь" to="/help" />
        )}
        {USERS_ROLE[role] === ROLE.ADMIN && (
          <CustomLink text="ПОЛЬЗОВАТЕЛИ" to="/users" />
        )}
        {managerEmail && (
          <div>
            <span className={styles.your_manager_text}>Ваш менеджер: </span>
            <span className={styles.your_manager_email}>{managerEmail}</span>
          </div>
        )}
        <span className={styles.email}>{email}</span>
        <div className={styles.logout} onClick={onLogoutHandler}>
          <span>Выйти</span>
          <img className={styles.logoutIcon} src={logoutIcon} alt="" />
        </div>
        <div className={styles.affise_button}>
          <button className={styles.button}>В Affise</button>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
