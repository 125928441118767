/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import ResponseInfo from "../../components/responseInfo/ResponseInfo";
import Button from "../../components/ui/button/Button";
import Input from "../../components/ui/inputs/input/Input";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { errorsArray } from "../../core/helper";
import { registration } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import { delayByLoader } from "../../utils/utils";
import styles from "./Registration.module.css";

const Registration = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState({ email: { value: "", error: false } });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isExist, setIsExist] = useState(false);

  const userErrors = useSelector(currentUserSel.errors);

  useEffect(() => {
    delayByLoader(true, setLoading, 200);
  }, []);

  useEffect(() => {
    let theFields = errorsArray(data, userErrors);
    if (theFields) {
      setData({ ...theFields });
    }
  }, [data, userErrors]);

  const onRegistrationHAndler = () => {
    dispatch(registration(data.email.value, onSuccess, setIsExist));
  };

  const onSuccess = () => {
    delayByLoader(true, setLoading, 200);

    setSuccess(true);
  };

  return loading ? (
    <ContentLoader zIndex />
  ) : (
    <div className={styles.Registration}>
      {loading && <ContentLoader zIndex />}
      <div className={styles.Logo}>
        <img src={Logo} alt="x-partners logo" />
      </div>
      <div className={styles.Content}>
        {loading && <ContentLoader zIndex />}
        {!success ? (
          <>
            <h1 className={styles.Title}>Registration</h1>
            <div className={styles.InputBlock}>
              <Input
                type="email"
                typeInput="authorization"
                label="E-MAIL"
                placeholder="Введите e-mail зарегистрированный на my.x-partners.com"
                required={true}
                value={data.email.value}
                setValue={(val) =>
                  setData({ ...data, email: { value: val, error: false } })
                }
                error={data.email.error}
              />
              <div className={styles.FootBlock}>
                <Button
                  className={styles.registration_button}
                  onClick={onRegistrationHAndler}
                >
                  Create account
                </Button>
                <Link to="/login">Sign in</Link>
              </div>
            </div>
          </>
        ) : (
          <ResponseInfo />
        )}
      </div>
    </div>
  );
};

export default Registration;
