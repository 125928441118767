import React from "react";
import AppContent from "../../components/appContent/AppContent";
import classes from "./NotFound.module.css";

const NotFound = () => {
  return (
    <AppContent className={classes.NotFound}>
      <div className={classes.main404}>
        <h1 className={classes.pageHeader}>Ошибка 404</h1>
        <p>Ой! Похоже, такой страницы не существует или она потерялась</p>
      </div>
    </AppContent>
  );
};

export default NotFound;
