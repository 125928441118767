import { ACTION_TYPE } from "../constants/tab";
import $api from "../core/api";
import { processError, processErrorValidation } from "../core/helper";
import { currentUserOp } from "../store/currentUser";
import { globalOp } from "../store/global";
import { usersOp } from "../store/users";
import { delayByLoader } from "../utils/utils";
import { createNotification } from "./app";

export const checkAuth = (navigate, redirectLandings) => {
  return async (dispatch) => {
    try {
      const response = await $api.get("/api/user/refresh");
      localStorage.setItem("token", response.data.accessToken);
      dispatch(currentUserOp.handleSetCurrentUser(response.data.user));
      dispatch(currentUserOp.handleIsAuthState(true));
      dispatch(globalOp.handleLoaderState(false));
      redirectLandings();
    } catch (e) {
      processError(dispatch, e);
      localStorage.removeItem("token");
      navigate("/login");
    } finally {
      setTimeout(() => {
        dispatch(globalOp.handleSetAuthLoader(false));
      }, 2000);
    }
  };
};

export const registration = (email, onSuccess, setIsExist) => {
  return async (dispatch) => {
    try {
      await $api.post(`/api/user/registration`, {
        email: email,
      });

      onSuccess();
    } catch (e) {
      if (e?.response?.data?.errors === 1) {
        setIsExist(true);
      }
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const activationAccount = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      await $api.post(`/api/user/activation`, data);
      dispatch(
        createNotification({
          type: "success",
          children: "Пароль успешно обновлён",
        })
      );
      onSuccess?.();
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const login = (email, password, setLoading) => {
  setLoading(true);
  return async (dispatch) => {
    try {
      const response = await $api.post(`/api/user/login`, { email, password });
      delayByLoader(false, setLoading).then(() => {
        localStorage.setItem("token", response.data.accessToken);
        dispatch(currentUserOp.handleSetCurrentUser(response.data.user));
        dispatch(currentUserOp.handleIsAuthState(true));
      }, 500);
    } catch (e) {
      processErrorValidation({ dispatch, error: e });
    } finally {
      delayByLoader(false, setLoading, 500);
    }
  };
};

export const logout = (navigate) => {
  return async (dispatch) => {
    dispatch(globalOp.handleSetAuthLoader(true));
    try {
      await $api.post(`/api/user/logout`);
      localStorage.removeItem("token");
      dispatch(currentUserOp.handleSetCurrentUser({}));
      dispatch(currentUserOp.handleIsAuthState(false));
    } catch (e) {
      processError(dispatch, e);
    } finally {
      setTimeout(() => {
        dispatch(globalOp.handleSetAuthLoader(false));
        navigate("/login");
      }, 2000);
    }
  };
};

export const getPrivilegedUsers = (role) => {
  return async (dispatch) => {
    !role && dispatch(globalOp.handleLoaderState(true));
    try {
      const response = await $api.post(`/api/user/getPrivilegedUsers`);
      const data = response.data;

      dispatch(usersOp.handleSetUsersListData(data));
    } catch (e) {
      processErrorValidation({ dispatch, error: e });
    } finally {
      dispatch(globalOp.handleLoaderState(false));
    }
  };
};

export const createPrivilegedUser = (
  name,
  surname,
  email,
  password,
  role,
  setContentLoader,
  onSuccess
) => {
  return async (dispatch) => {
    setContentLoader(true);
    try {
      const response = await $api.post(`/api/user/createPrivilegedUser`, {
        name,
        surname,
        email,
        password,
        role,
      });

      const id = response.data._id;

      dispatch(
        createNotification({
          type: "success",
          children: `Новый ${
            role ? "Администратор" : "Модератор"
          } успешно создан !`,
        })
      );
      onSuccess(ACTION_TYPE.ADD, id);
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    } finally {
      setContentLoader(false);
    }
  };
};

export const removePrivilegedUser = (removeId, setContentLoader, onSuccess) => {
  return async (dispatch) => {
    setContentLoader(true);

    try {
      const response = await $api.delete(
        `/api/user/removePrivilegedUser/?id=${removeId}`
      );

      const id = response.data.id;

      dispatch(
        createNotification({
          type: "success",
          children: `Пользователь удалён`,
        })
      );
      onSuccess(ACTION_TYPE.REMOVE, id);
    } catch (e) {
      processErrorValidation({ dispatch, error: e });
    } finally {
      setContentLoader(false);
    }
  };
};

export const updatePrivilegedUser = (
  id,
  name,
  surname,
  email,
  password,
  role,
  setContentLoader,
  onSuccess
) => {
  return async (dispatch) => {
    setContentLoader(true);
    try {
      const response = await $api.patch(`/api/user/updatePrivilegedUser`, {
        id,
        name,
        surname,
        email,
        password,
        role,
      });

      const userRole = response.data.role;

      dispatch(
        createNotification({
          type: "success",
          children: `Пользователь Обновлен`,
        })
      );

      onSuccess(ACTION_TYPE.UPDATE, id, +userRole);
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const recoveringPassword = (email, onSuccess, setIsExist) => {
  return async (dispatch) => {
    try {
      await $api.post(`/api/user/recoveringPassword`, {
        email,
      });

      onSuccess();
    } catch (e) {
      if (e?.response?.data?.errors === 1) {
        setIsExist(true);
      }
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const updatePassword = (
  id,
  recoverId,
  password,
  setLoading,
  navigate
) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      await $api.post(`/api/user/updatePassword`, {
        id,
        recoverId,
        password,
      });

      dispatch(
        createNotification({
          type: "success",
          children: "Пароль успешно обновлён",
        })
      );

      setTimeout(() => {
        navigate("/login");
      }, 500);
      delayByLoader(false, setLoading, 600);
    } catch (e) {
      setLoading(false);

      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};

export const sendEmail = (body, setContentLoader, onSuccess) => {
  return async (dispatch) => {
    try {
      setContentLoader(true);
      await $api.post(`/api/user/sendSupportEmail`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(
        createNotification({
          type: "success",
          children: "Сообщение отправлено",
        })
      );

      onSuccess();
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    } finally {
      setContentLoader(false);
    }
  };
};

export const getAllActiveAndPublicOffers = (enabledOffers, setOffersNotExist) => {
  return async (dispatch) => {
    try {
      const response = await $api.get(`/api/user/offers`);

      const data = response.data;

      for (let i = 0; i < enabledOffers.length; i++) {
        data.reduce((acc, item) => {
          if (item.offer_id === enabledOffers[i] && item.offer_id !== '1007') {
            item.privacy = "public";
          }
          if (item.offer_id === '1007'){
            setOffersNotExist?.(item)
          }
          return acc;
        });
      }

      const filteredData = data.filter((item) => item.privacy === "public");

      dispatch(currentUserOp.handleSetOffers(filteredData));
    } catch (e) {
      dispatch(
        createNotification({
          type: "error",
          children:
            e?.response?.data?.errors?.[0]?.msg || e.response.data.message,
        })
      );
    }
  };
};
