import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LANDING_STATUS_TYPE } from "../../../constants/tab";
import { getAllLanding, removeLanding } from "../../../service/landing_api";
import { currentUserSel } from "../../../store/currentUser";
import { landingSel } from "../../../store/landing";
import { delayByLoader } from "../../../utils/utils";
import LandingRemoveModal from "../../modals/landingRemoveModal/LandingRemoveModal";
import ContentLoader from "../../ui/loaders/contentLoader/ContentLoader";
import CreateLandingButton from "../createLandingButton/CreateLandingButton";
import styles from "./LandingMobileContent.module.css";
import LandingMobileItem from "./landingMobileItem/LandingMobileItem";

const LandingMobileContent = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editLandingData, setEditLandingData] = useState({
    id: null,
    title: "",
  });

  const landingsData = useSelector(landingSel.landingsData);
  const currentUser = useSelector(currentUserSel.currentUser);

  useEffect(() => {
    delayByLoader(true, setLoader, 300);
  }, []);

  const removeHandler = () => {
    const body = {
      status: LANDING_STATUS_TYPE.ARCHIVED,
      id: editLandingData.id,
    };

    dispatch(removeLanding(body, onSuccess, setModalShow));
  };

  const onSuccess = () => {
    delayByLoader(true, setLoader, 300);
    dispatch(getAllLanding(currentUser.role));
    setModalShow(false);
  };

  const onSetModalShow = (id, title) => {
    if (!!id) {
      setModalShow(true);
      setEditLandingData({ id, title });
    }
  };

  return (
    <div className={styles.container}>
      {loader && <ContentLoader />}

      <div
        style={{
          marginBottom: 24,
        }}
      >
        {landingsData.map((item, i) => {
          return (
            <div key={item._id} className={styles.item_wrapper}>
              <LandingMobileItem
                data={item}
                index={i}
                onSetModalShow={onSetModalShow}
              />
            </div>
          );
        })}
      </div>
      <LandingRemoveModal
        data={editLandingData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        removeHandler={removeHandler}
      />
      <CreateLandingButton />
    </div>
  );
};

export default LandingMobileContent;
