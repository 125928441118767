import { createSlice } from "@reduxjs/toolkit";
import { initialGlobalState } from "./initialState";

export const globalSlice = createSlice({
  name: "global",
  initialState: initialGlobalState,
  reducers: {
    setLoader(state, action) {
      state.loader = action.payload;
    },
    setContentLoader(state, action) {
      state.contentLOader = action.payload;
    },
    setAuthLoader(state, action) {
      state.authLoader = action.payload;
    },
    addNotification(state, action) {
      state.notifications = [...state.notifications, action.payload];
    },
    removeNotification(state, action) {
      state.notifications = action.payload;
    },
  },
});
