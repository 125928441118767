/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyLanding from "../../components/landingPage/emptyLanding/EmptyLanding";
import LandingContent from "../../components/landingPage/landingContent/LandingContent";
import LandingMobileContent from "../../components/landingPage/landingMobileContent/LandingMobileContent";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getAllLanding } from "../../service/landing_api";
import { currentUserSel } from "../../store/currentUser";
import { landingSel } from "../../store/landing";
import { delayByLoader } from "../../utils/utils";
import classes from "./LandingPage.module.css";

const LandingPage = () => {
  const [width] = useWindowSize();
  const dispatch = useDispatch();

  const [componentLoader, setComponentLoader] = useState(true);

  const landingsData = useSelector(landingSel.landingsData);
  const currentUser = useSelector(currentUserSel.currentUser);

  useEffect(() => {
    dispatch(getAllLanding(currentUser.role));

    delayByLoader(true, setComponentLoader, 300);
  }, []);

  return (
    <>
      {componentLoader && <ContentLoader top={60} zIndex />}
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Ваши Лендинги</h2>
        </div>
        {landingsData.length > 0 ? (
          width < 992 ? (
            <LandingMobileContent />
          ) : (
            <LandingContent />
          )
        ) : (
          <EmptyLanding />
        )}
      </div>
    </>
  );
};

export default LandingPage;
