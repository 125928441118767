import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import ResponseInfo from "../../components/responseInfo/ResponseInfo";
import Button from "../../components/ui/button/Button";
import Input from "../../components/ui/inputs/input/Input";
import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { errorsArray } from "../../core/helper";
import { recoveringPassword } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import { delayByLoader } from "../../utils/utils";
import styles from "./RecoveringPasswordPage.module.css";

const RecoveringPasswordPage = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isExist, setIsExist] = useState(false);

  const userErrors = useSelector(currentUserSel.errors);

  useEffect(() => {
    delayByLoader(true, setLoading, 200);
  }, []);

  useEffect(() => {
    let theFields = errorsArray(email, userErrors);
    if (theFields) {
      setEmail({ ...theFields });
    }
  }, [email, userErrors]);

  const onSuccess = () => {
    delayByLoader(true, setLoading, 200);

    setSuccess(true);
  };

  const onRecoveringPasswordHandler = () => {
    dispatch(recoveringPassword(email, onSuccess, setIsExist));
  };

  return loading ? (
    <ContentLoader zIndex />
  ) : (
    <div className={styles.container}>
      {loading && <ContentLoader zIndex />}
      <div className={styles.Logo}>
        <img src={Logo} alt="x-partners logo" />
      </div>
      {!success ? (
        <>
          <div className={styles.Content}>
            <h1 className={styles.Title}>Password Recovering</h1>
            <div className={styles.InputBlock}>
              <Input
                type="email"
                typeInput="authorization"
                label="E-MAIL"
                placeholder="Введите e-mail зарегистрированный на my.x-partners.com"
                required={true}
                value={email}
                setValue={(val) => setEmail(val)}
              />
              <div className={styles.FootBlock}>
                <Button
                  onClick={onRecoveringPasswordHandler}
                  className={styles.registration_button}
                >
                  Recover
                </Button>
                {isExist && <Link to="/registration">Create Account</Link>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ResponseInfo />
      )}
    </div>
  );
};

export default RecoveringPasswordPage;
