import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabAndCreate from "../../components/tabAndCreate/TabAndCreate";
import UsersContent from "../../components/usersContent/UsersContent";
import styles from "./UsersPage.module.css";

import ContentLoader from "../../components/ui/loaders/contentLoader/ContentLoader";
import { getPrivilegedUsers } from "../../service/user_api";
import { currentUserSel } from "../../store/currentUser";
import { globalSel } from "../../store/global";
import { usersOp } from "../../store/users";

const UsersPage = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSel.currentUser);

  const contentLOader = useSelector(globalSel.contentLOader);

  useEffect(() => {
    dispatch(getPrivilegedUsers());

    return () => {
      dispatch(usersOp.handleSetUsersListData([]));
    };
  }, [dispatch, currentUser.role]);

  return (
    <>
      {contentLOader && <ContentLoader top={60} zIndex />}
      <div className={styles.container}>
        <h2 className={styles.title}>Управление пользователями</h2>
        <TabAndCreate />
        <UsersContent />
      </div>
    </>
  );
};

export default UsersPage;
