import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ApprovePassive from "../../../assets/img/landing/approve_passive.png";
import FirstRect from "../../../assets/img/landing/first_rect.png";
import ModerationPassive from "../../../assets/img/landing/moderation_passive.png";
import styles from "./LandingLifeCycle.module.css";

const LandingLifeCycle = () => {
  return (
    <Container className={styles.container}>
      <Row>
        <Col xs={4} className={styles.col}>
          <div
            className={styles.first_element}
            style={{
              backgroundImage: `url(${FirstRect})`,
            }}
          >
            <span className={styles.status_text}>Подготовка к модерации</span>
          </div>
        </Col>
        <Col xs={4} className={styles.col}>
          <div
            style={{
              backgroundImage: `url(${ModerationPassive})`,
            }}
          >
            <span
              style={{
                color: "#A7A7A7",
              }}
              className={styles.status_text}
            >
              На модерации
            </span>
          </div>
        </Col>
        <Col xs={4} className={styles.col}>
          <div
            style={{
              backgroundImage: `url(${ApprovePassive})`,
            }}
          >
            <span
              style={{
                color: "#A7A7A7",
              }}
              className={styles.status_text}
            >
              Прошел модерацию
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingLifeCycle;
