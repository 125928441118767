import { currentUsersSlice } from "./currentUsersSlice";

const {
  setCurrentUser,
  setIsAuthState,
  setErrors,
  updateSelectedTab,
  setOffers,
  setSelectedLandingTab,
} = currentUsersSlice.actions;

const handleSetCurrentUser = (state) => (dispatch) => {
  dispatch(setCurrentUser(state));
};

const handleIsAuthState = (state) => (dispatch) => {
  dispatch(setIsAuthState(state));
};

const handleSetErrors = (state) => (dispatch) => {
  dispatch(setErrors(state));
};

const handleUpdateSelectedTab = (state) => (dispatch) => {
  dispatch(updateSelectedTab(state));
};

const handleSetOffers = (state) => (dispatch) => {
  dispatch(setOffers(state));
};

const handleSetSelectedLandingTab = (state) => (dispatch) => {
  dispatch(setSelectedLandingTab(state));
};

const operations = {
  handleSetCurrentUser,
  handleIsAuthState,
  handleSetErrors,
  handleUpdateSelectedTab,
  handleSetOffers,
  handleSetSelectedLandingTab,
};

export default operations;
