import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { globalOp } from "../../../store/global";
import classes from "./Notification.module.css";
import NotificationContent from "./notificationContent/NotificationContent";

const Notification = ({ id, type, className, children }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [css] = useState(false);

  const notificationClasses = [classes.Notification];

  if (className && classes[className]) {
    notificationClasses.push(classes[className]);
  }

  const closeNotification = useCallback(() => {
    const notification = document.getElementById(id);

    setShow(false);

    notification.style = `
            transform:translateY(-100px);
            opacity: 0;
            height: 0;
            margin-bottom: 0;
            z-index: 0;
        `;
  }, [id]);

  useEffect(() => {
    let timeOutClose = setTimeout(() => {
      closeNotification();
    }, 3000);

    return () => {
      clearTimeout(timeOutClose);
    };
  }, [closeNotification]);

  useEffect(() => {
    if (!show) {
      let timeOutClose = setTimeout(() => {
        dispatch(globalOp.handleRemoveNotification([]));
      }, 600);

      return () => {
        clearTimeout(timeOutClose);
      };
    }
  }, [show, dispatch, id]);

  return (
    <CSSTransition
      in={show}
      timeout={0}
      classNames={{
        enterActive: classes.NotificationActive,
        enterDone: classes.NotificationDone,
        exit: classes.NotificationExit,
        exitDone: classes.NotificationExitDone,
      }}
      appear={true}
    >
      <div
        id={id}
        className={`${notificationClasses?.join(" ")}`}
        {...(css && { style: css })}
      >
        <NotificationContent
          type={type}
          onClick={() => {
            closeNotification();
          }}
        >
          {children}
        </NotificationContent>
      </div>
    </CSSTransition>
  );
};

export default Notification;
